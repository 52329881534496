import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Badge,
  Tooltip,
  Menu,
  MenuItem,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';
import { motion } from 'framer-motion';
import { get } from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useThemeStore from 'Theme/store';
// Mobx
import { inject, observer } from 'mobx-react';
// Icons
import {
  ChatBubbleLeftRightIcon,
  MagnifyingGlassIcon,
  PhotoIcon,
  PlusCircleIcon,
  SpeakerWaveIcon,
  StarIcon,
  XMarkIcon,
  ChevronRightIcon,
  ChevronDownIcon,
  ArrowTopRightOnSquareIcon,
  BookmarkSquareIcon as BookmarkSquareIconOutline,
} from '@heroicons/react/24/outline';
import CheckIcon from '@mui/icons-material/Check';
import {
  AcademicCapIcon,
  BookOpenIcon,
  BriefcaseIcon,
  ClipboardDocumentListIcon,
  CommandLineIcon,
  NewspaperIcon,
  PhotoIcon as PhotoIconSolid,
  RectangleStackIcon,
  SparklesIcon,
} from '@heroicons/react/24/solid';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PencilSquareIcon from '../../../Theme/icons/editIcon';
import ModelsIcon from '../../../Theme/icons/modelsIcon';
import { icons as modelIcons } from '../../../Theme/icons/modelsIcons/index';
import StraicoSymbolIcon from '../../../Theme/icons/straicoSymbol';
import TemplatesIcon from '../../../Theme/icons/templatesIcon';
// Components
import ConversationSide from 'Components/Chat/ConversationSide/ConversationSide';
import PromptSide from 'Components/Chat/PromptSide/PromptSide';
import useChatStore, {
  CAPABILITIES_CHANGE,
  EVENT_NEW_CHAT,
  EVENT_SELECT_CHAT,
  LOADING_FAV_TOOLS,
  REMOVE_MODEL,
  SCRIPT_FOR_TTS,
} from 'Components/Chat/store';
import Loader from 'Components/Loader';
import {
  ChooseTemplate,
  CreateSmartTemplate,
  CreateTemplatesForm,
} from 'Features/CreateTemplatesForm';
import ChatModelSelectorDialog from './CapabilitiesDialog/ChatModelSelectorDialog';
import toast from 'react-hot-toast';

const PowerPanel = ({ store }) => {
  const { theme: themeValue } = useThemeStore();
  const { multi_default_models } = store.profile;

  const navigate = useNavigate();
  const location = useLocation();

  // Panel open/close
  const powerPanelOpen = useChatStore((state) => state.powerPanelOpen);
  const setPowerPanelOpen = useChatStore((state) => state.setPowerPanelOpen);

  // Favorites tools
  const [loadingFavTools, setLoadingFavTools] = useState(false);
  const [favTools, setFavTools] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  // Chats and selection
  const loadingChats = useChatStore((state) => state.loadingChats);
  const selectedChat = useChatStore((state) => state.selectedChat);
  const chatList = useChatStore((state) => state.chatList);

  // Models
  const selectedModels = useChatStore((state) => state.selectedModels);
  const updateSelectedModels = useChatStore(
    (state) => state.updateSelectedModels
  );
  const setShowModelsDialog = useChatStore(
    (state) => state.setShowModelsDialog
  );

  // UI for combos
  const {
    combos,
    combosLoading,
    selectedCombo,
    fetchCombos,
    setSelectedCombo,
    createCombo,
  } = useChatStore();
  const [allModels, setAllModels] = useState([]);

  // Accordions
  const [openModels, setOpenModels] = useState(false);
  const [openAccordion, setOpenAccordion] = useState(null);

  // Panel resizing
  const pannelWidth = useChatStore((state) => state.pannelWidth);
  const setPannelWidth = useChatStore((state) => state.setPannelWidth);
  const panelRef = useRef(null);
  const rafRef = useRef(null);
  const minWidth = 320;
  const maxWidth = 640;
  const [isResizing, setIsResizing] = useState(false);
  const [isAtLimit, setIsAtLimit] = useState(null);
  const newWidth = useRef(pannelWidth);
  const resizeStartXRef = useRef(null);
  const initialWidthRef = useRef(null);

  // Chat events
  const currentEvent = useChatStore((state) => state.currentEvent);
  const addEvent = useChatStore((state) => state.addEvent);
  const runEvent = useChatStore((state) => state.runEvent);

  // Chat capabilities
  const [capabilities, setCapabilities] = useState(
    JSON.parse(localStorage.getItem('capabilities')) || []
  );
  const [isChatModelDialogOpen, setIsChatModelDialogOpen] = useState(false);

  // Combos menu
  const [anchorEl, setAnchorEl] = useState(null);

  // Combos "Save new" dialog
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [comboName, setComboName] = useState('');
  const [error, setError] = useState('');
  const maxComboNameLength = 15;
  const invalidName = 'Custom Combination';

  // ------------- FETCH LOGIC & SIDE-EFFECTS ------------- //

  // If combos not loaded yet, fetch them
  useEffect(() => {
    if (!combos.length) {
      fetchCombos();
    }
  }, [combos.length, fetchCombos]);

  // Fetch all models for combos logic
  useEffect(() => {
    const fetchModels = async () => {
      try {
        const response = await store.api.get(`/model`);
        setAllModels(response.data.models || []);
      } catch (error) {
        console.error('Error fetching models:', error);
      }
    };

    fetchModels();
  }, [store.api]);

  // Fetch favorite tools
  const fetchFavs = async () => {
    setLoadingFavTools(true);
    try {
      const data = await store.fetchFavorites();
      if (data && data.data) {
        const filteredTools = data.data.filter((tool) => !tool.in_trash);
        setFavTools(sortArrayAlphabetically(filteredTools));
      } else {
        console.error('No favorite tools or unexpected format found:', data);
      }
    } catch (error) {
      console.error('Error getting favorite tools:', error);
    } finally {
      setLoadingFavTools(false);
    }
  };

  // On mount, fetch favorites
  useEffect(() => {
    fetchFavs();
  }, []);

  // Listen for global chat events
  useEffect(() => {
    if (currentEvent) {
      eventActions(currentEvent);
    }
  }, [currentEvent]);

  // ------------- CAPABILITIES & EVENTS ------------- //

  const eventActions = (event) => {
    switch (event.name) {
      case LOADING_FAV_TOOLS:
        runEvent(fetchFavs);
        break;
      case CAPABILITIES_CHANGE:
        runEvent(refreshCapabilities);
        break;
      default:
        break;
    }
  };

  const refreshCapabilities = () => {
    setCapabilities(JSON.parse(localStorage.getItem('capabilities')) || []);
  };

  const hasActiveCapabilities = () => {
    return Array.isArray(capabilities) && capabilities.length > 0;
  };

  // ------------- MODEL + CAPABILITY UTILS ------------- //

  const checkCapabilities = (model) => {
    if (model.function_calls && model.function_calls.length) {
      const modelCapabilities = capabilities.filter((capability) =>
        model.function_calls
          .map((func) => func.capability)
          .includes(capability._id)
      );
      return modelCapabilities;
    }
    return null;
  };

  const activeCapability = (model) => {
    const cap = checkCapabilities(model);
    return cap && cap.length;
  };

  const joinCapabilities = (model) => {
    const capabilitiesList = (checkCapabilities(model) || []).map(
      (c) => c.name + ' capability enabled'
    );
    return [model.name, ...capabilitiesList].join('\n');
  };

  // ------------- COMBOS UI HANDLERS ------------- //

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleNavigation = (path) => {
    navigate(path);
    setTimeout(() => {
      const targetSection = document.querySelector('#wordcapLimitsSection');
      if (targetSection) {
        targetSection.scrollIntoView({ behavior: 'smooth' });
        targetSection.focus();
      }
    }, 500);
  };

  const handleComboClick = async (combo) => {
    setSelectedCombo(combo);

    if (combo && Array.isArray(combo.llms)) {
      try {
        const selectedModels = combo.llms
          .map((llmId) => allModels.find((m) => m._id === llmId))
          .filter(Boolean)
          .sort((a, b) => a.abbreviation.localeCompare(b.abbreviation));

        updateSelectedModels(() => selectedModels);
        localStorage.setItem('currentModels', JSON.stringify(selectedModels));
      } catch (error) {
        console.error('Error processing combo:', error);
      }
    }
    handleMenuClose();
  };

  // Show dialog for saving new combos
  const handleSaveClick = () => {
    setComboName('');
    setError('');
    setIsDialogOpen(true);
  };
  const handleDialogClose = () => {
    setIsDialogOpen(false);
    setComboName('');
    setError('');
  };

  // Validate combo name
  const validateComboName = (name) => {
    const trimmedName = name.trim();
    if (!trimmedName) {
      return 'The combo name cannot be empty.';
    }
    if (trimmedName.toLowerCase() === invalidName.toLowerCase()) {
      return `The name "${invalidName}" is not allowed.`;
    }
    if (trimmedName.length > maxComboNameLength) {
      return `The name cannot exceed ${maxComboNameLength} characters.`;
    }
    const isDuplicateName = combos.some(
      (combo) => combo.name.toLowerCase() === trimmedName.toLowerCase()
    );
    if (isDuplicateName) {
      return 'A combo with this name already exists.';
    }
    return null;
  };

  const handleInputChange = (e) => {
    setComboName(e.target.value);
    setError('');
  };

  // Save combo logic
  const handleSaveCombo = async () => {
    const trimmedName = comboName.trim();
    const validationError = validateComboName(trimmedName);

    if (validationError) {
      setError(validationError);
      toast.error(validationError);
      return;
    }

    // Ensure no combo with the exact same set of models
    const selectedModelIds = selectedCombo.llms.slice().sort();
    const isDuplicateModels = combos.some((combo) => {
      const comboModelIds = combo.llms.slice().sort();
      return (
        comboModelIds.length === selectedModelIds.length &&
        comboModelIds.every((id, idx) => id === selectedModelIds[idx])
      );
    });
    if (isDuplicateModels) {
      const msg = 'A combo with the same models already exists.';
      setError(msg);
      toast.error(msg);
      return;
    }

    // Attempt to create
    try {
      const comboData = { ...selectedCombo, name: trimmedName };
      const newCombo = await createCombo(comboData);

      await fetchCombos();
      setSelectedCombo(newCombo);
      toast.success('Combo saved successfully!');
      handleDialogClose();
    } catch (error) {
      console.error('Error saving combo:', error);
      toast.error('Failed to save combo.');
    }
  };

  // Re-validate combo name on every change
  useEffect(() => {
    if (comboName) {
      const validationError = validateComboName(comboName.trim());
      setError(validationError);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comboName]);

  // ------------- TABS & ACCORDIONS ------------- //

  const handleIconClick = (accordionId) => {
    setPowerPanelOpen(true);
    if (accordionId === 'models') {
      setOpenModels(true);
    } else {
      setOpenAccordion(accordionId);
    }
    navigate('/chat');
  };

  const handleAccordionChange = (accordionId) => (event, isExpanded) => {
    setOpenAccordion(isExpanded ? accordionId : false);
  };

  // ------------- DRAG RESIZE LOGIC (MASTER) ------------- //

  const preventSelection = () => {
    let style = document.createElement('style');
    style.id = 'prevent-selection';
    style.textContent = `
      * {
        user-select: none !important;
        -webkit-user-select: none !important;
        -moz-user-select: none !important;
        -ms-user-select: none !important;
        cursor: col-resize !important;
      }
    `;
    document.head.appendChild(style);
  };

  const restoreSelection = () => {
    const style = document.getElementById('prevent-selection');
    if (style) {
      style.remove();
    }
  };

  const handleMouseDown = (e) => {
    e.preventDefault();
    resizeStartXRef.current = e.clientX;
    initialWidthRef.current = panelRef.current.offsetWidth;
    setIsResizing(true);
    preventSelection();
  };

  const handleMouseUp = () => {
    if (isResizing) {
      if (rafRef.current) {
        cancelAnimationFrame(rafRef.current);
        rafRef.current = null;
      }
      setPannelWidth(newWidth.current);
      setIsResizing(false);
      setIsAtLimit(null);
      resizeStartXRef.current = null;
      initialWidthRef.current = null;
      restoreSelection();
    }
  };

  const updateResize = (clientX) => {
    const deltaX = clientX - resizeStartXRef.current;
    const newValue = initialWidthRef.current + deltaX;

    if (newValue <= minWidth) {
      setIsAtLimit('min');
      newWidth.current = minWidth;
    } else if (newValue >= maxWidth) {
      setIsAtLimit('max');
      newWidth.current = maxWidth;
    } else {
      setIsAtLimit(null);
      newWidth.current = newValue;
    }

    if (panelRef.current) {
      panelRef.current.style.width = `${newWidth.current}px`;
    }
  };

  const handleMouseMove = (e) => {
    if (!isResizing) return;
    if (rafRef.current) {
      cancelAnimationFrame(rafRef.current);
    }
    rafRef.current = requestAnimationFrame(() => {
      updateResize(e.clientX);
    });
  };

  useEffect(() => {
    if (isResizing) {
      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('mouseup', handleMouseUp);
    }
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
      restoreSelection();
    };
  }, [isResizing]);

  // ------------- UTILS / SORT ------------- //

  const sortArrayAlphabetically = (array) => {
    return array.sort((a, b) => a.title.localeCompare(b.title));
  };

  const sortByAttribute = (arr, attribute) => {
    return arr?.sort((a, b) => {
      if (a[attribute] < b[attribute]) {
        return -1;
      }
      if (a[attribute] > b[attribute]) {
        return 1;
      }
      return 0;
    });
  };

  const getIcon = (icon) => {
    const Icon = get(modelIcons, icon);
    return Icon ? (
      <Icon className="h-6 w-6" />
    ) : (
      <ModelsIcon className="h-6 w-6 stroke-raisin-black dark:stroke-crystal-bell" />
    );
  };

  // ------------- NEW CHAT LOGIC ------------- //

  const handlePreserveModels = async (models) => {
    addEvent(EVENT_NEW_CHAT);
    await new Promise((resolve) => {
      setTimeout(() => {
        updateSelectedModels(() => sortByAttribute(models, 'abbreviation'));
        localStorage.setItem('currentModels', JSON.stringify(models));
        resolve();
      }, 100);
    });
  };

  const areModelsDifferent = () => {
    const currentModelIds = selectedModels.map((model) => model._id);
    return (
      currentModelIds.length !== multi_default_models.length ||
      currentModelIds.some((id) => !multi_default_models.includes(id))
    );
  };

  const areModelsCompatibleWithCapabilities = () => {
    return selectedModels.every((model) => {
      const modelCapabilities = checkCapabilities(model);
      return !!(modelCapabilities && modelCapabilities.length > 0);
    });
  };

  const handleNewChatClick = () => {
    if (hasActiveCapabilities()) {
      if (areModelsCompatibleWithCapabilities()) {
        handlePreserveModels(selectedModels);
        toast.success(
          'Models were automatically reselected as they are compatible with the capabilities'
        );
      } else {
        // If they're different from default models => prompt user
        if (areModelsDifferent()) {
          setIsChatModelDialogOpen(true);
        } else {
          addEvent(EVENT_NEW_CHAT);
        }
      }
    } else {
      addEvent(EVENT_NEW_CHAT);
    }
  };

  // ------------- ACCORDION STYLING ------------- //

  const baseAccordionStyles = () => ({
    backgroundColor: themeValue === 'dark' ? '#1D1C1E' : '#EDEDF2',
    color: themeValue === 'dark' ? '#EFEFEF' : '#211E21',
    '&:before': {
      display: 'none',
    },
    overflow: 'hidden',
  });

  const accordionStyles = () => ({
    ...baseAccordionStyles(),
    '&.Mui-expanded': {
      flex: '1 1 0%',
      overflow: 'auto',
      display: 'flex',
      flexDirection: 'column',
    },
    '& > .MuiCollapse-root': {
      flex: '1 1 0%',
      overflow: 'auto',
      display: 'flex',
      flexDirection: 'column',
      maxHeight: 'calc(100vh - 266px)',
    },
    '& > .MuiCollapse-root > .MuiCollapse-wrapper': {
      flex: '1 1 0%',
      overflow: 'auto',
      display: 'flex',
      flexDirection: 'column',
    },
    '& > .MuiCollapse-root > .MuiCollapse-wrapper > .MuiCollapse-wrapperInner':
      {
        flex: '1 1 0%',
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column',
      },
    '& > .MuiCollapse-root > .MuiCollapse-wrapper > .MuiCollapse-wrapperInner > .MuiAccordion-region':
      {
        flex: '1 1 0%',
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column',
      },
    '& > .MuiCollapse-root > .MuiCollapse-wrapper > .MuiCollapse-wrapperInner > .MuiAccordion-region > .MuiAccordionDetails-root':
      {
        flex: '1 1 0%',
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column',
      },
  });

  // ------------- FAV TOOLS UI ------------- //

  const onClickTemplate = (tool) => {
    const urlParams = new URLSearchParams(location.search);
    urlParams.set('selectedTool', tool?._id);
    navigate(
      {
        pathname: location.pathname !== '/chat' ? '/chat' : location.pathname,
        search: urlParams.toString(),
      },
      { shallow: true }
    );
  };

  const getToolIconByCategory = (tool) => {
    switch (tool.category) {
      case 'Image Generation':
        return <PhotoIconSolid className="w-4 h-4" />;
      case 'Writing':
        return <ClipboardDocumentListIcon className="w-4 h-4" />;
      case 'Resources':
        return <RectangleStackIcon className="w-4 h-4" />;
      case 'Business':
        return <BriefcaseIcon className="w-4 h-4" />;
      case 'Career Development':
        return <AcademicCapIcon className="w-4 h-4" />;
      case 'Content':
        return <NewspaperIcon className="w-4 h-4" />;
      case 'Programming':
        return <CommandLineIcon className="w-4 h-4" />;
      case 'Image Prompting':
        return <SparklesIcon className="w-4 h-4" />;
      default:
        return <BookOpenIcon className="w-4 h-4" />;
    }
  };

  // ------------- MAIN RENDER ------------- //

  return (
    <>
      {/* Chat Model Dialog */}
      <ChatModelSelectorDialog
        isOpen={isChatModelDialogOpen}
        onClose={() => setIsChatModelDialogOpen(false)}
        onPreserveModels={handlePreserveModels}
        onResetToDefaultModels={() => {
          addEvent(EVENT_NEW_CHAT);
          setIsChatModelDialogOpen(false);
        }}
        currentModels={selectedModels}
        themeValue={themeValue}
      />

      {/* Templates */}
      <ChooseTemplate />
      <CreateTemplatesForm />
      <CreateSmartTemplate store={store} />

      {/* MAIN SIDE PANEL */}
      <div
        ref={panelRef}
        className={`relative h-full z-50 pt-5 flex flex-col items-center font-figtree ${
          themeValue === 'dark' ? 'bg-umbra' : 'bg-ghost-white'
        } duration-200 max-md:hidden ${
          powerPanelOpen ? `w-[${pannelWidth}px]` : '!w-14 !gap-4'
        }`}
      >
        {/* Top Logo & Buttons */}
        <div
          className={`${
            powerPanelOpen && 'w-full mb-4'
          } flex items-center justify-between px-5`}
        >
          <StraicoSymbolIcon
            className={`h-9 fill-nue-blue cursor-pointer select-none duration-500 ${
              powerPanelOpen && 'rotate-[360deg]'
            }`}
            onClick={() => setPowerPanelOpen(!powerPanelOpen)}
          />
          <div className="flex justify-end space-x-2.5">
            {powerPanelOpen && (
              <>
                <Tooltip
                  title="Open text to speech generator"
                  arrow
                  placement="bottom"
                >
                  <div
                    className={`w-[38px] h-[38px] flex justify-center items-center rounded-xl cursor-pointer select-none ${
                      themeValue === 'dark'
                        ? 'bg-ship-grey text-stargate-shimmer'
                        : 'bg-lavender text-violet-blue'
                    }`}
                    onClick={() => addEvent(SCRIPT_FOR_TTS)}
                  >
                    <SpeakerWaveIcon className="w-[20px] h-[20px]" />
                  </div>
                </Tooltip>
                <Tooltip
                  title="Access Stable Diffusion image generator"
                  arrow
                  placement="bottom"
                >
                  <div
                    className={`w-[38px] h-[38px] flex justify-center items-center rounded-xl cursor-pointer select-none ${
                      themeValue === 'dark'
                        ? 'bg-ship-grey text-stargate-shimmer'
                        : 'bg-lavender text-violet-blue'
                    }`}
                    onClick={() => {
                      setPowerPanelOpen(false);
                      navigate('/images');
                    }}
                  >
                    <PhotoIcon className="w-[20px] h-[20px]" />
                  </div>
                </Tooltip>
                <div
                  className="flex items-center justify-center gap-2 px-2 h-[38px] text-white bg-nue-blue hover:bg-nue-blue/70 cursor-pointer rounded-xl select-none"
                  onClick={handleNewChatClick}
                >
                  <PlusCircleIcon className="h-[20px] w-[20px]" />
                  <span>New chat</span>
                </div>
              </>
            )}
          </div>
        </div>

        {/* Collapsed panel icons */}
        {!powerPanelOpen && (
          <>
            <Tooltip title="New chat" placement="right" arrow>
              <div
                className="flex items-center justify-center w-[34px] h-[34px] bg-nue-blue hover:bg-nue-blue/70 cursor-pointer rounded-xl"
                onClick={handleNewChatClick}
              >
                <PlusCircleIcon className="h-[21px] w-[21px] text-white" />
              </div>
            </Tooltip>

            {/* Collapsed combos button */}
            <Tooltip
              title={selectedCombo?.name || 'No combo selected'}
              placement="right"
              arrow
            >
              <div
                className="flex items-center justify-center w-[34px] h-[34px] cursor-pointer relative"
                onClick={handleMenuOpen}
              >
                <span
                  className="text-lg font-bold absolute inset-0 flex items-center justify-center"
                  style={{ color: '#9091A4' }}
                >
                  {selectedCombo?.name?.charAt(0) || '-'}
                </span>
                <ChevronRightIcon
                  className={`h-[23px] w-[23px] ml-6 transition-transform duration-300 ${
                    Boolean(anchorEl) ? 'rotate-180' : ''
                  }`}
                  style={{ color: '#9091A4' }}
                />
              </div>
            </Tooltip>

            {/* Collapsed combos menu */}
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
              PaperProps={{
                style: {
                  maxHeight: '300px',
                  overflow: 'hidden',
                  width: '17%',
                  backgroundColor:
                    themeValue === 'dark' ? '#252425' : '#FBFBFA',
                  border: '1.5px solid #9091A461',
                  borderRadius: '10px',
                  marginTop: '-40px',
                  marginLeft: '45px',
                  boxShadow: 'none',
                },
                elevation: 0,
              }}
            >
              {combosLoading && <MenuItem disabled>Loading combos...</MenuItem>}
              {!combosLoading && combos.length === 0 && (
                <MenuItem disabled>No combos available</MenuItem>
              )}
              {!combosLoading &&
                combos.length > 0 &&
                combos.map((combo) => (
                  <MenuItem
                    key={combo._id}
                    onClick={() => handleComboClick(combo)}
                    sx={{
                      '&:hover': {
                        backgroundColor:
                          themeValue === 'dark' ? '#3B3B45' : '#EDEDF2',
                      },
                      transition: 'background-color 0.3s ease',
                      fontSize: '14px',
                    }}
                  >
                    {combo.name}
                  </MenuItem>
                ))}
              {/* Go to settings page button */}
              {!combosLoading && combos.length > 0 && (
                <div
                  style={{
                    textAlign: 'center',
                    marginTop: '8px',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      gap: '8px',
                      marginLeft: '8px',
                    }}
                  >
                    <Button
                      variant="contained"
                      onClick={() => {
                        handleNavigation('/user-settings#personalizeAI');
                        setPowerPanelOpen(false);
                        setAnchorEl(null);
                      }}
                      startIcon={
                        <ArrowTopRightOnSquareIcon
                          style={{
                            width: 20,
                            height: 20,
                            color:
                              themeValue === 'dark' ? '#7F82C3' : '#5256A6',
                          }}
                        />
                      }
                      style={{
                        backgroundColor:
                          themeValue === 'dark' ? '#3B3B45' : '#D4D5E6',
                        color: themeValue === 'dark' ? '#7F82C3' : '#5256A6',
                        textTransform: 'none',
                        width: '90%',
                        height: 30,
                        borderRadius: '10px',
                        boxShadow: 'none',
                        fontWeight: 600,
                        fontSize: '14px',
                      }}
                    >
                      Go to settings page
                    </Button>
                    {/* If user is on custom combo, show Save New Combo */}
                    {selectedCombo?._id === 'custom' && (
                      <Tooltip title="Save New Combo" arrow placement="right">
                        <div
                          onClick={handleSaveClick}
                          className={`w-[34px] h-[34x] bg-lavender hover:bg-ghost-white cursor-pointer rounded-[10px] dark:bg-ship-grey dark:hover:bg-quicksilver justify-center items-center flex select-none mr-2`}
                        >
                          <BookmarkSquareIconOutline className="w-[1] h-[1] text-violet-blue dark:text-tropical-indigo" />
                        </div>
                      </Tooltip>
                    )}
                  </div>
                </div>
              )}
            </Menu>
            {/* Collapsed selected models */}
            {selectedModels.map((model, index) => (
              <div className="relative group" key={index}>
                <Badge
                  color="primary"
                  variant="dot"
                  invisible={!activeCapability(model)}
                >
                  <Tooltip
                    className="flex gap-2 cursor-pointer select-none"
                    title={
                      <span style={{ whiteSpace: 'pre-line' }}>
                        {joinCapabilities(model)}
                      </span>
                    }
                    onClick={() => setShowModelsDialog(true)}
                    placement="right"
                    arrow
                    componentsProps={{
                      tooltip: {
                        sx: {
                          bgcolor: '#D4D5E6',
                          color: '#5256A6',
                          '& .MuiTooltip-arrow': {
                            color: '#D4D5E6',
                          },
                        },
                      },
                    }}
                  >
                    <div>{getIcon(model.icon)}</div>
                  </Tooltip>
                </Badge>

                {selectedModels.length > 1 && (
                  <div
                    className="hidden group-hover:block absolute z-50 p-[1px] rounded cursor-pointer select-none -left-1 -bottom-1 bg-lavender dark:bg-ship-grey text-violet-blue dark:text-stargate-shimmer"
                    onClick={() => addEvent(REMOVE_MODEL, model)}
                  >
                    <XMarkIcon className="w-[10px] h-[10px] stroke-[3px]" />
                  </div>
                )}
              </div>
            ))}
          </>
        )}

        {/* Combos "Save New" dialog */}
        <Dialog
          open={isDialogOpen}
          onClose={handleDialogClose}
          PaperProps={{
            style: {
              borderRadius: '21px',
              backgroundColor: themeValue === 'dark' ? '#252425' : '#F7F7F8',
              width: '90%',
              maxWidth: '400px',
              height: 'auto',
            },
            elevation: 0,
          }}
        >
          <DialogTitle
            sx={{
              textAlign: 'center',
              color: themeValue === 'dark' ? '#EFEFEF' : '#211E21',
              fontWeight: 700,
              fontFamily: 'Figtree, sans-serif',
              fontSize: '24px',
              lineHeight: '28px',
              '@media (max-width:600px)': {
                fontSize: '20px',
              },
            }}
          >
            Save New LLM Combination
          </DialogTitle>
          <DialogContent
            sx={{
              paddingBottom: '1px',
              '@media (max-width:600px)': {
                padding: '8px',
              },
            }}
          >
            <TextField
              autoFocus
              margin="dense"
              label="Custom Combination Name"
              type="text"
              fullWidth
              variant="outlined"
              placeholder="Custom Combination"
              value={comboName}
              onChange={handleInputChange}
              error={Boolean(error)}
              helperText={error}
              InputProps={{
                style: {
                  borderRadius: '12px',
                  padding: 0,
                  backgroundColor:
                    themeValue === 'dark' ? '#323232' : '#FBFBFA',
                },
              }}
            />
          </DialogContent>
          <DialogActions
            sx={{
              justifyContent: 'center',
              marginTop: '-8px',
              '@media (max-width:600px)': {
                marginTop: '0px',
              },
              gap: '36%',
            }}
          >
            <Button
              onClick={handleDialogClose}
              sx={{
                backgroundColor: themeValue === 'dark' ? '#3B3B45' : '#D4D5E6',
                color: themeValue === 'dark' ? '#7F82C3' : '#5256A6',
                textTransform: 'none',
                display: 'flex',
                alignItems: 'center',
                gap: '4px',
                '&:hover': {
                  backgroundColor:
                    themeValue === 'dark' ? '#34343E' : '#C7C9DE',
                },
                '@media (max-width:600px)': {
                  fontSize: '14px',
                  padding: '6px 12px',
                },
              }}
            >
              <XMarkIcon className="w-[16px] h-[16px]" />
              Cancel
            </Button>
            <Button
              onClick={handleSaveCombo}
              sx={{
                backgroundColor: '#464BBA',
                color: '#FFFFFF',
                textTransform: 'none',
                display: 'flex',
                alignItems: 'center',
                gap: '4px',
                '&:hover': {
                  backgroundColor: '#3B40A0',
                },
                '@media (max-width:600px)': {
                  fontSize: '14px',
                  padding: '6px 12px',
                },
              }}
            >
              <CheckIcon /> Save
            </Button>
          </DialogActions>
        </Dialog>

        {/* Expanded panel view */}
        {powerPanelOpen ? (
          <div className="w-full h-full flex-1 flex flex-col overflow-auto">
            {/* Current Models Accordion */}
            <Accordion
              disableGutters
              elevation={0}
              square
              sx={baseAccordionStyles()}
              expanded={openModels}
              onChange={(event, isExpanded) => setOpenModels(isExpanded)}
            >
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon
                    sx={{
                      fontSize: '0.9rem',
                      color: themeValue === 'dark' ? '#EFEFEF' : '#211E21',
                    }}
                  />
                }
                aria-controls="models-content"
                id="models-header"
              >
                <ModelsIcon className="h-[21px] w-[21px] stroke-raisin-black dark:stroke-crystal-bell mr-3" />
                <div className="flex-1 font-semibold">Current models</div>
              </AccordionSummary>
              <AccordionDetails sx={{ paddingTop: 0, paddingBottom: 0 }}>
                <div
                  className={`flex flex-col gap-2 rounded-xl p-2 border-[1.5px] ${
                    themeValue === 'dark'
                      ? 'bg-lead border-sonic-silver'
                      : 'bg-white border-cool-gray/35'
                  }`}
                >
                  {/* Combos row */}
                  <div className="flex items-center gap-2">
                    {/* Combos select / "custom" */}
                    <div className="flex-grow flex-shrink basis-[80%]">
                      {combosLoading && <p>Loading combos...</p>}
                      {!combosLoading && combos.length === 0 && (
                        <p>No combos available</p>
                      )}
                      {!combosLoading && combos.length > 0 && (
                        <div
                          onClick={handleMenuOpen}
                          style={{
                            backgroundColor:
                              themeValue === 'dark' ? '#252425' : '#FBFBFA',
                            border:
                              themeValue === 'dark'
                                ? '1.5px solid #464BBA'
                                : '1.5px solid #9091A461',
                            borderRadius: '10px',
                            padding: '4px',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            cursor: 'pointer',
                          }}
                        >
                          <p
                            style={{
                              margin: 0,
                              marginLeft: '8px',
                              fontSize: '14px',
                              color:
                                themeValue === 'dark' ? '#ffffff' : '#211E21',
                            }}
                          >
                            {selectedCombo?.name || 'No combo selected'}
                          </p>
                          <ChevronDownIcon
                            className={`h-5 w-5 text-violet-blue dark:text-tropical-indigo cursor-pointer transition-transform duration-200 ${
                              Boolean(anchorEl) ? 'rotate-180' : ''
                            }`}
                          />
                        </div>
                      )}
                    </div>
                    <Tooltip title="Choose models" arrow placement="right">
                      <div
                        onClick={() => setShowModelsDialog(true)}
                        className={`w-[32px] h-[32px] bg-lavender hover:bg-ghost-white cursor-pointer rounded-[10px] dark:bg-ship-grey dark:hover:bg-quicksilver justify-center items-center flex select-none`}
                      >
                        <PencilSquareIcon className="w-[5] h-[5] text-violet-blue dark:text-tropical-indigo" />
                      </div>
                    </Tooltip>
                    {selectedCombo?._id === 'custom' && (
                      <Tooltip title="Save New Combo" arrow placement="right">
                        <div
                          onClick={handleSaveClick}
                          className={`w-[32px] h-[32px] bg-lavender hover:bg-ghost-white cursor-pointer rounded-[10px] dark:bg-ship-grey dark:hover:bg-quicksilver justify-center items-center flex select-none`}
                        >
                          <BookmarkSquareIconOutline className="w-[1] h-[1] text-violet-blue dark:text-tropical-indigo" />
                        </div>
                      </Tooltip>
                    )}
                  </div>

                  {/* Combos drop-down menu */}
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                    PaperProps={{
                      style: {
                        maxHeight: '200px',
                        overflow: 'hidden',
                        width: 'auto',
                        backgroundColor:
                          themeValue === 'dark' ? '#252425' : '#FBFBFA',
                        border: '1.5px solid #9091A461',
                        borderRadius: '10px',
                        marginTop: '6px',
                        boxShadow: 'none',
                      },
                      elevation: 0,
                    }}
                  >
                    {combosLoading && (
                      <MenuItem disabled>Loading combos...</MenuItem>
                    )}
                    {!combosLoading && combos.length === 0 && (
                      <MenuItem disabled>No combos available</MenuItem>
                    )}
                    {!combosLoading && combos.length > 0 && (
                      <>
                        <div
                          style={{
                            maxHeight: '200px',
                            overflowY: 'auto',
                            backgroundColor:
                              themeValue === 'dark' ? '#252425' : '#FBFBFA',
                          }}
                        >
                          {combos.map((combo) => (
                            <MenuItem
                              key={combo._id}
                              onClick={() => handleComboClick(combo)}
                              style={{
                                backgroundColor:
                                  themeValue === 'dark' ? '#252425' : '#FBFBFA',
                                color:
                                  themeValue === 'dark' ? '#FFFFFF' : '#211E21',
                                borderRadius: '6px',
                                margin: '4px 0',
                                padding: '8px 16px',
                                transition: 'background-color 0.3s ease',
                                cursor: 'pointer',
                                fontSize: '14px',
                              }}
                              onMouseEnter={(e) => {
                                e.target.style.backgroundColor =
                                  themeValue === 'dark' ? '#3B3B45' : '#EDEDF2';
                              }}
                              onMouseLeave={(e) => {
                                e.target.style.backgroundColor =
                                  themeValue === 'dark' ? '#252425' : '#FBFBFA';
                              }}
                            >
                              {combo.name}
                            </MenuItem>
                          ))}
                        </div>
                        <div
                          style={{
                            textAlign: 'center',
                            backgroundColor:
                              themeValue === 'dark' ? '#252425' : '#FBFBFA',
                          }}
                        >
                          <Button
                            variant="contained"
                            onClick={() => {
                              handleNavigation('/user-settings#personalizeAI');
                              setPowerPanelOpen(false);
                              setAnchorEl(null);
                            }}
                            startIcon={
                              <ArrowTopRightOnSquareIcon
                                style={{
                                  width: 20,
                                  height: 20,
                                  color:
                                    themeValue === 'dark'
                                      ? '#7F82C3'
                                      : '#5256A6',
                                }}
                              />
                            }
                            style={{
                              backgroundColor:
                                themeValue === 'dark' ? '#3B3B45' : '#D4D5E6',
                              color:
                                themeValue === 'dark' ? '#7F82C3' : '#5256A6',
                              textTransform: 'none',
                              width: '90%',
                              height: '40px',
                              boxShadow: 'none',
                              fontSize: '13px',
                            }}
                            PaperProps={{
                              style: {
                                boxShadow: 'none',
                              },
                              elevation: 0,
                            }}
                          >
                            Go to settings page
                          </Button>
                        </div>
                      </>
                    )}
                  </Menu>

                  {/* List of selected models */}
                  <div className="flex flex-col gap-2 pt-2">
                    {selectedModels.map((model, index) => (
                      <div className="relative group" key={index}>
                        <Badge
                          color="primary"
                          variant="dot"
                          invisible={!activeCapability(model)}
                          anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                        >
                          <Tooltip
                            className="flex gap-2 cursor-pointer select-none"
                            title={
                              <span style={{ whiteSpace: 'pre-line' }}>
                                {joinCapabilities(model)}
                              </span>
                            }
                            placement="right"
                            arrow
                            componentsProps={{
                              tooltip: {
                                sx: {
                                  bgcolor: '#D4D5E6',
                                  color: '#5256A6',
                                  '& .MuiTooltip-arrow': {
                                    color: '#D4D5E6',
                                  },
                                },
                              },
                            }}
                          >
                            <div className="flex items-center gap-2">
                              {getIcon(model.icon)}
                              <span
                                style={{ paddingTop: '2px' }}
                                className="flex-1 line-clamp-1 text-sm text-agate-violet dark:text-palladium"
                              >
                                {model.abbreviation}
                              </span>
                            </div>
                          </Tooltip>
                        </Badge>

                        {selectedModels.length > 1 && (
                          <div
                            className="hidden group-hover:block absolute z-50 p-[1px] rounded cursor-pointer select-none -left-1 -bottom-1 bg-lavender dark:bg-ship-grey text-violet-blue dark:text-stargate-shimmer"
                            onClick={() => addEvent(REMOVE_MODEL, model)}
                          >
                            <XMarkIcon className="w-[10px] h-[10px] stroke-[3px]" />
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>

            {/* Chats Accordion */}
            <Accordion
              disableGutters
              elevation={0}
              square
              sx={accordionStyles()}
              expanded={openAccordion === 'chats'}
              onChange={handleAccordionChange('chats')}
            >
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon
                    sx={{
                      fontSize: '0.9rem',
                      color: themeValue === 'dark' ? '#EFEFEF' : '#211E21',
                    }}
                  />
                }
                aria-controls="chats-content"
                id="chats-header"
              >
                <ChatBubbleLeftRightIcon className="h-[21px] w-[21px] mr-3" />
                <div className="flex-1 font-semibold">My chats</div>
              </AccordionSummary>
              <AccordionDetails sx={{ padding: 0 }}>
                <motion.div className="z-10 h-full relative flex-1 flex flex-col overflow-auto">
                  <ConversationSide
                    show={true}
                    onClick={() => addEvent(EVENT_NEW_CHAT)}
                    onSelectChat={(chat) => addEvent(EVENT_SELECT_CHAT, chat)}
                    loading={loadingChats}
                    chatSelectedIndex={selectedChat?.index}
                    chats={chatList}
                    currentChatId={selectedChat?.chat}
                    newDesing
                  />
                </motion.div>
              </AccordionDetails>
            </Accordion>

            {/* Templates Accordion */}
            <Accordion
              disableGutters
              elevation={0}
              square
              sx={accordionStyles()}
              expanded={openAccordion === 'templates'}
              onChange={handleAccordionChange('templates')}
            >
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon
                    sx={{
                      fontSize: '0.9rem',
                      color: themeValue === 'dark' ? '#EFEFEF' : '#211E21',
                    }}
                  />
                }
                aria-controls="tamplates-content"
                id="tamplates-header"
              >
                <TemplatesIcon className="h-[21px] w-[21px] stroke-raisin-black dark:stroke-crystal-bell mr-3" />
                <div className="flex-1 font-semibold">Templates</div>
              </AccordionSummary>
              <AccordionDetails sx={{ padding: 0 }}>
                <PromptSide newDesing />
              </AccordionDetails>
            </Accordion>

            {/* Favorites Accordion */}
            <Accordion
              disableGutters
              elevation={0}
              square
              sx={accordionStyles()}
              expanded={openAccordion === 'favorites'}
              onChange={handleAccordionChange('favorites')}
            >
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon
                    sx={{
                      fontSize: '0.9rem',
                      color: themeValue === 'dark' ? '#EFEFEF' : '#211E21',
                    }}
                  />
                }
                aria-controls="favorites-content"
                id="favorites-header"
              >
                <StarIcon className="h-[21px] w-[21px] mr-3" />
                <div className="flex-1 font-semibold">Favorite Templates</div>
              </AccordionSummary>
              <AccordionDetails sx={{ padding: 0 }}>
                <div className="flex-1 h-full flex flex-col font-figtree">
                  {loadingFavTools ? (
                    <div className="flex items-center justify-center h-full">
                      <Loader />
                    </div>
                  ) : favTools.length > 0 ? (
                    <>
                      <div
                        className={`flex items-center relative rounded-[10px] transition mx-5 mb-2 ${
                          themeValue === 'dark'
                            ? 'focus-within:border-[#787878]'
                            : 'focus-within:border-[#CFD0D6]'
                        } border-2 border-transparent rounded-[12px]`}
                      >
                        <input
                          autoFocus
                          id="keyword"
                          className={`appearance-none ${
                            themeValue === 'dark'
                              ? 'dark:bg-night-black dark:text-crystal-bell'
                              : 'bg-white text-raisin-black'
                          } h-8 text-sm focus:outline-none transition w-full pl-8 p-1 rounded-[10px] placeholder-battleship-gray`}
                          autoComplete="off"
                          type="text"
                          name="keyword"
                          placeholder="Search"
                          value={searchTerm}
                          onChange={(e) => setSearchTerm(e.target.value)}
                        />
                        <span
                          className={`${
                            themeValue === 'dark'
                              ? 'dark:text-[#787878]'
                              : 'text-[#51526C]'
                          } absolute left-2.5`}
                        >
                          <MagnifyingGlassIcon className="h-4 w-4" />
                        </span>
                      </div>
                      <div className="flex-1 overflow-auto px-5">
                        {favTools
                          .filter((tool) =>
                            tool.title
                              .toLowerCase()
                              .includes(searchTerm.toLowerCase())
                          )
                          .map((tool) => (
                            <div
                              key={tool._id}
                              className={`flex py-[5px] cursor-pointer select-none items-start gap-2 group text-sm ${
                                themeValue === 'dark'
                                  ? 'text-palladium'
                                  : 'text-agate-violet'
                              }`}
                              onClick={() => onClickTemplate(tool)}
                            >
                              <div className="text-sm flex items-center gap-2 w-full py-[2px] hover:bg-platinum dark:hover:bg-ship-grey rounded">
                                <div className="icon-wrapper">
                                  {getToolIconByCategory(tool)}
                                </div>
                                <div className="flex-1 line-clamp-1">
                                  {tool.title}
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                    </>
                  ) : (
                    <div className="px-5 text-center">
                      You don't have favorite templates.
                    </div>
                  )}
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
        ) : (
          /* Collapsed Panel Icons */
          <>
            <Tooltip title="Models" placement="right" arrow>
              <div
                onClick={() => setShowModelsDialog(true)}
                className="flex items-center gap-3 text-raisin-black dark:text-crystal-bell cursor-pointer select-none"
              >
                <ModelsIcon className="h-[21px] w-[21px] stroke-raisin-black dark:stroke-crystal-bell" />
              </div>
            </Tooltip>
            <Tooltip title="Chats" placement="right" arrow>
              <div
                onClick={() => handleIconClick('chats')}
                className="flex items-center gap-3 text-raisin-black dark:text-crystal-bell cursor-pointer select-none"
              >
                <ChatBubbleLeftRightIcon className="h-[21px] w-[21px]" />
              </div>
            </Tooltip>
            <Tooltip title="Templates" placement="right" arrow>
              <div
                onClick={() => handleIconClick('templates')}
                className="flex items-center gap-3 text-raisin-black dark:text-crystal-bell cursor-pointer select-none"
              >
                <TemplatesIcon className="h-[21px] w-[21px] stroke-raisin-black dark:stroke-crystal-bell" />
              </div>
            </Tooltip>
            <Tooltip title="Favorites" placement="right" arrow>
              <div
                onClick={() => handleIconClick('favorites')}
                className="flex items-center gap-3 text-raisin-black dark:text-crystal-bell cursor-pointer select-none"
              >
                <StarIcon className="h-[21px] w-[21px]" />
              </div>
            </Tooltip>
          </>
        )}

        {/* Resizing handle (if expanded) */}
        {powerPanelOpen && (
          <div
            className={`group absolute bottom-0 top-0 flex w-5 cursor-col-resize left-full ${
              isAtLimit ? 'transition-colors duration-200' : ''
            }`}
            onMouseDown={handleMouseDown}
          >
            <div className="relative">
              <div
                className={`absolute top-0 h-full w-0.5 -translate-x-1/2 transition-all duration-150 ${
                  isAtLimit
                    ? 'opacity-100 !bg-pottery-red'
                    : 'opacity-0 group-hover:opacity-70 bg-nue-blue'
                }`}
              ></div>
              <div
                className={`absolute top-0 h-full -translate-x-1/2 transition-all duration-150 w-1 blur-md ${
                  isAtLimit
                    ? 'opacity-100 !bg-pottery-red'
                    : 'opacity-0 group-hover:opacity-70 bg-nue-blue'
                }`}
              ></div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default inject('store')(observer(PowerPanel));
