import React from 'react';
import LLMExistingCombos from 'Components/Forms/LLMCombosBars/LLMExistingCombos';

class ComboItemTemplate extends React.Component {
  render() {
    const { item, dragHandleProps } = this.props;
    return (
      <div
        {...dragHandleProps}
        style={{
          cursor: 'grab',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <LLMExistingCombos
          id={item.id}
          title={item.name}
          modelList={item.llms}
          onSelect={item.onSelect}
          store={item.store}
          theme={item.theme}
        />
      </div>
    );
  }
}

export default ComboItemTemplate;
