import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import toast from 'react-hot-toast';
const EVENT_NEW_CHAT = 'on_new_chat';
const EVENT_SELECT_CHAT = 'on_select_chat';
const SCRIPT_FOR_TTS = 'script_for_tts';
const LOADING_FAV_TOOLS = 'loading_fav_tools';
const CAPABILITIES_CHANGE = 'capabilities_change';
const REMOVE_MODEL = 'remove_model';
// Render types
const TABS = 'tabs';
const COLUMNS = 'columns';
const ROWS = 'rows';
const GRID = 'grid';

const useChatStore = create(
  persist(
    (set, get) => ({
      // States
      chatList: [],
      selectedChat: {
        index: null,
        chat: {
          _id: null,
          messages: [],
        },
      },
      loadingChats: false,
      totalPages: 1,
      currentPage: 1,
      totalChats: 0,
      chatWords: 0,
      refFile: undefined,
      powerPanelOpen: false,
      selectedModels: [],
      showModelsDialog: false,
      currentEvent: {
        name: null,
        params: {},
      },
      modelsList: [],
      renderType: TABS,
      maxMessageModels: 1,
      hideOnHover: false,
      headerVisible: true,
      addResponseModels: false,
      pannelWidth: 320,
      showMultiFileToggled: false,
      combos: [],
      combosLoading: false,
      selectedCombo: null,
      isEditingCombo: false,

      // Functions
      setTotalPages: (totalPages) => {
        set(() => ({
          totalPages,
        }));
      },
      setTotalChats: (totalChats) => {
        set(() => ({
          totalChats,
        }));
      },
      setCurrentPage: (currentPage) => {
        set(() => ({
          currentPage,
        }));
      },
      selectChat: (chatId) => {
        set((state) => ({
          selectedChat: {
            index: (state.chatList.length ? state.chatList : []).findIndex(
              (chat) => chat._id === chatId
            ),
            chat: (state.chatList.length ? state.chatList : []).find(
              (chat) => chat._id === chatId
            ),
          },
        }));
      },
      setSelectedChat: (chat) => {
        set((state) => ({
          selectedChat: {
            ...state.selectedChat,
            chat,
          },
        }));
      },
      cleanSelectedChat: () => {
        set(() => ({
          selectedChat: {
            index: null,
            chat: {
              _id: null,
              messages: [],
            },
          },
        }));
      },
      setChatsList: (newChatData) => {
        set(() => ({
          chatList: newChatData,
        }));
      },
      setLoadingChats: (loading) => {
        set(() => ({
          loadingChats: loading,
        }));
      },
      cleanChatList: () => {
        set(() => ({
          chatList: [],
        }));
      },
      setChatWords: (chatWords) => {
        set(() => ({
          chatWords,
        }));
      },
      setRefFile: (refFile) => {
        set(() => ({
          refFile,
        }));
      },
      updateRefFile: (updateFunc) => {
        set((state) => ({
          refFile: updateFunc(state.refFile),
        }));
      },
      setPowerPanelOpen: (powerPanelOpen) => {
        set(() => ({
          powerPanelOpen,
        }));
      },
      updateSelectedModels: (updateFunc) => {
        set((state) => {
          const updatedModels = updateFunc(state.selectedModels);
          get().verifyComboMatch(updatedModels);
          return {
            selectedModels: updatedModels,
          };
        });
      },

      setShowModelsDialog: (showModelsDialog) => {
        set(() => ({
          showModelsDialog,
        }));
      },
      addEvent: (event, params = {}) => {
        set(() => ({
          currentEvent: {
            name: event,
            params: params,
          },
        }));
      },
      removeEvent: () => {
        set(() => ({
          currentEvent: {
            name: null,
            params: {},
          },
        }));
      },
      runEvent: async (callback) => {
        const { currentEvent } = get();
        if (currentEvent && currentEvent.name) {
          Object.entries(currentEvent.params).length === 0
            ? await callback()
            : await callback(currentEvent.params);
          get().removeEvent();
        }
      },
      setModelsList: (modelsList) => {
        set(() => ({
          modelsList,
        }));
      },
      setRenderType: (renderType) => {
        const previousRenderType = get().renderType;
        const selectedChat = get().selectedChat;
        const appStore = window.store;
        appStore.sendMetricsEvent(
          'change_view',
          { chat: selectedChat.chat._id },
          { from_view: previousRenderType, to_view: renderType }
        );

        set(() => ({
          renderType,
        }));
      },
      setMaxMessageModels: (maxMessageModels) => {
        set(() => ({
          maxMessageModels,
        }));
      },
      setHideOnHover: (hideOnHover) => {
        set(() => ({
          hideOnHover,
        }));
      },
      setHeaderVisible: (headerVisible) => {
        set(() => ({
          headerVisible,
        }));
      },
      setAddResponseModels: (addResponseModels) => {
        set(() => ({
          addResponseModels,
        }));
      },
      setPannelWidth: (pannelWidth) => {
        set(() => ({
          pannelWidth,
        }));
      },
      setShowMultiFileToggled: (showMultiFileToggled) => {
        set(() => ({
          showMultiFileToggled,
        }));
      },
      setIsEditingCombo: (isEditing) => {
        set(() => ({ isEditingCombo: isEditing }));
      },
      verifyComboMatch: (selectedModels) => {
        const { combos, setSelectedCombo, isEditingCombo } = get();
        if (isEditingCombo) return;

        const matchingCombo = combos.find((combo) => {
          const comboModels = combo.llms || [];
          const selectedModelIds = selectedModels
            .map((model) => model._id)
            .sort();
          return (
            comboModels.length === selectedModelIds.length &&
            comboModels
              .sort()
              .every((id, index) => id === selectedModelIds[index])
          );
        });

        if (matchingCombo) {
          setSelectedCombo(matchingCombo);
        } else {
          const customCombo = {
            _id: 'custom',
            name: 'Custom Combination',
            llms: selectedModels.map((model) => model._id),
            isDefault: false,
          };
          setSelectedCombo(customCombo);
        }
      },
      setSelectedCombo: (combo) => {
        set(() => ({ selectedCombo: combo }));

        if (combo && combo.llms) {
          const sortedModels = combo.llms
            .map((id) => ({ _id: id }))
            .sort((a, b) => a._id.localeCompare(b._id));
          localStorage.setItem('currentModels', JSON.stringify(sortedModels));
        }
      },

      updateSelectedCombo: async (comboId) => {
        const appStore = window.store;

        try {
          await Promise.all(
            get().combos.map((combo) =>
              appStore.api.put(`/combos/llm-combos/${combo._id}`, {
                isSelected: combo._id === comboId,
              })
            )
          );

          const updatedCombos = get().combos.map((combo) => ({
            ...combo,
            isSelected: combo._id === comboId,
          }));

          const selectedCombo = updatedCombos.find(
            (combo) => combo._id === comboId
          );

          set(() => ({
            combos: updatedCombos,
            selectedCombo,
          }));
        } catch (error) {
          console.error('Error updating selected combo:', error);
        }
      },
      initializeSelectedCombo: () => {
        const combos = get().combos;
        if (combos.length) {
          const defaultCombo = combos.find((c) => c.isSelected);
          set(() => ({ selectedCombo: defaultCombo || combos[0] }));
        }
      },
      async fetchCombos() {
        set(() => ({ combosLoading: true }));

        try {
          const appStore = window.store;
          const response = await appStore.api.get('/combos/llm-combos');
          set(() => ({
            combos: response.data.combos || [],
          }));
          get().initializeSelectedCombo();
        } catch (error) {
          console.error('Error fetching combos:', error);
        } finally {
          set(() => ({ combosLoading: false }));
        }
      },
      createCombo: async (comboData) => {
        try {
          const appStore = window.store;
          const response = await appStore.api.post(
            '/combos/llm-combos',
            comboData
          );

          set((state) => ({
            combos: [...state.combos, response.data.combo],
          }));

          set(() => ({
            selectedCombo: response.data.combo,
          }));

          return response.data.combo;
        } catch (error) {
          console.error('Error creating combo:', error);
          throw new Error('Failed to create combo');
        }
      },
      isDefaultCombo: (id) => {
        const combos = get().combos;
        const combo = combos.find((combo) => combo._id === id);
        return combo ? combo.name === 'Default' : false;
      },
      deleteCombo: async (id) => {
        const appStore = window.store;
        const { combos } = get();

        // Find the combo to delete
        const comboToDelete = combos.find((combo) => combo._id === id);

        // Check if the combo is the primary one (position 1)
        if (comboToDelete.position === 1) {
          toast.error("You can't delete the main combo");
          return; // Prevent further execution if it's the main combo
        }

        try {
          await appStore.api.delete(`/combos/llm-combos/${id}`);
          set((state) => ({
            combos: state.combos.filter((combo) => combo._id !== id),
          }));
          toast.success('Combo deleted successfully');
        } catch (error) {
          console.error('Error deleting combo:', error);
          toast.error('Failed to delete the combo');
        }
      },
    }),
    {
      name: 'chat-render-type',
      partialize: (state) => ({
        renderType: state.renderType,
        pannelWidth: state.pannelWidth,
      }),
    }
  )
);

export default useChatStore;

export {
  EVENT_NEW_CHAT,
  EVENT_SELECT_CHAT,
  SCRIPT_FOR_TTS,
  LOADING_FAV_TOOLS,
  CAPABILITIES_CHANGE,
  REMOVE_MODEL,
};

export { TABS, COLUMNS, ROWS, GRID };
