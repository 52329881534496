/* eslint-disable no-console */
import useChatStore from 'Components/Chat/store';
import { MAX_MODELS_SELECTION } from 'Constants/models';
import CommonModal from 'Features/CreateTemplatesForm/Components/CommonModal';
import CapabilitiesContainer from 'Features/ModelsModal/components/capabilitiesContainer';
import FiltersContainer from 'Features/ModelsModal/components/filtersContainer';
import ModelsContainer from 'Features/ModelsModal/components/modelsContainer';
import TabsContainer from 'Features/ModelsModal/components/tabs';
import useCommonFunctionsModels from 'Features/ModelsModal/hooks/useCommonFunctionsModels';
import useModelsAndCapabilities from 'Features/ModelsModal/hooks/useModelsAndCapabilities';
import useModelsModalStore from 'Features/ModelsModal/store';
import { inject, observer } from 'mobx-react';
import toast from 'react-hot-toast';

const ModelsModal = ({
  setSelectedModels,
  selectedCapabilities,
  onCapabilities,
  store,
  open,
  onClose,
  // Props from your branch:
  nocapabilities,
  selectedModels: propSelectedModels,
  handleCombos,
  themeValue,
}) => {
  // COMMON MODAL STORE
  const { layout, tab, favorites, setRefetch, refetch } = useModelsModalStore();

  // CHAT STORE
  const {
    setModelsList,
    addResponseModels,
    selectedModels: chatStoreSelectedModels,
  } = useChatStore((state) => state);

  // Determine which selectedModels to use:
  const selectedModels = propSelectedModels || chatStoreSelectedModels;

  // GET MODELS AND CAPABILITIES DATA
  const { models, capabilities, isLoading, setModels } =
    useModelsAndCapabilities({
      store,
      setModelsList,
    });

  // COMMON FUNCTIONS
  const {
    isSelected,
    disabled,
    selected,
    isActive,
    getCompability,
    getIcon,
    getUncompability,
  } = useCommonFunctionsModels({
    capabilities,
    modelsSelected: selectedModels,
    capabilitiesSelected: selectedCapabilities,
    addResponseModels,
  });

  // Handle model selection
  const handleSelectModel = (model) => {
    if (isSelected(model)) {
      // Model already selected; remove it if > 1 model remains.
      const updatedModels = selectedModels.filter(
        (item) => item._id !== model._id
      );
      if (updatedModels.length > 0) {
        setSelectedModels(updatedModels);
      } else {
        toast('You must have at least one model selected', {
          icon: '⚠️',
        });
      }
    } else {
      // Model not selected; add it if under max
      if (selectedModels.length < MAX_MODELS_SELECTION) {
        setSelectedModels([...selectedModels, model]);
      } else {
        toast('You only can select up to 4 LLMs simultaneously', {
          icon: '⚠️',
        });
      }
    }
  };

  // If you provided a custom handler for combos, use it; otherwise default to normal selection
  const handleSelect = handleCombos || handleSelectModel;

  // Handle capability selection
  const handleSelectCapability = (capability) => {
    if (isActive(capability)) {
      // Already active => remove it
      const updatedCaps = selectedCapabilities?.filter(
        (item) => item._id !== capability._id
      );
      onCapabilities(updatedCaps);
    } else {
      // Not active => add it
      onCapabilities([...selectedCapabilities, capability]);
    }
  };

  // Local update of the model's favorite state
  const onStaredLocal = (model) => {
    if (model.favorite) {
      // Unfavorite
      const modelsArray = models.map((item) =>
        item._id === model._id ? { ...item, favorite: false } : item
      );
      setModels(modelsArray);
    } else {
      // Mark as favorite
      const modelsArray = models.map((item) =>
        item._id === model._id ? { ...item, favorite: true } : item
      );
      setModels(modelsArray);
    }
  };

  // Handle toggling a model's favorite state
  const handleToggleStar = async (model) => {
    onStaredLocal(model);
    try {
      if (!model.favorite) {
        // If it was NOT favorite, we are adding it
        await store.api.post('/v2/models/favorite', { modelId: model._id });
        toast.success('Model added to favorites');
      } else {
        // If it was already favorite, we are removing it
        await store.api.delete(`/v2/models/favorite/${model._id}`);
        toast.success('Model removed from favorites');
      }
      // If currently on "favorites" tab, force a refetch so the UI updates
      if (favorites) {
        setRefetch(refetch ? 0 : 1);
      }
    } catch (error) {
      console.error('Error adding model to favorites:', error);
      toast.error('Error adding model to favorites');
    }
  };

  // Renders for each tab
  const TabPaneRender = {
    0: (
      <ModelsContainer
        layout={layout}
        models={models}
        selected={selected}
        disabled={disabled}
        handleChange={handleSelect}
        isLoading={isLoading}
        handleToggleStar={handleToggleStar}
      />
    ),
    1: !nocapabilities && (
      <CapabilitiesContainer
        getIcon={getIcon}
        isActive={isActive}
        capabilities={capabilities}
        getCompability={getCompability}
        modelsSelected={selectedModels}
        getUncompability={getUncompability}
        handleCapabilities={handleSelectCapability}
      />
    ),
  };

  return (
    <CommonModal
      noPaddingX
      open={open}
      maxWidth="xl"
      fullWidth
      maxHeight="100%"
      withCloseButton
      handleClose={onClose}
    >
      <div className="p-7 w-full overflow-hidden flex flex-col font-figtree h-[90dvh]">
        <div className="flex gap-2 items-center text-raisin-black dark:text-crystal-bell mb-4">
          <h1 className="font-bold text-3xl">Select prompt feature</h1>
          {tab === 0 && (
            <div className="flex flex-col justify-center text-xs font-normal px-4 sm:px-6">
              <ul className="list-disc space-y-2">
                <li className="ml-5">
                  <span className="-ml-1">
                    Select your default LLM for all your chats{' '}
                    <a
                      className="font-semibold text-nue-blue underline"
                      href="https://platform.straico.com/user-settings"
                      target="_blank"
                      rel="noreferrer"
                    >
                      here
                    </a>{' '}
                    or delve deeper into our LLM options{' '}
                    <a
                      className="font-semibold text-nue-blue underline"
                      href="https://straico.com/multimodel/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      here
                    </a>
                  </span>
                </li>
                <li className="ml-5 mt-1">
                  <span className="-ml-1">
                    You can select up to 4 LLMs simultaneously
                  </span>
                </li>
              </ul>
            </div>
          )}
        </div>
        {/* TABS */}
        <TabsContainer nocapabilities={nocapabilities} />

        {/* Filters (only on tab 0) */}
        {tab === 0 && (
          <FiltersContainer
            nocapabilities={nocapabilities}
            selectedModels={selectedModels}
            handleSelectModel={handleSelectModel}
            models={models}
            themeValue={themeValue}
          />
        )}

        {/* Render the correct content for the current tab */}
        {TabPaneRender[tab]}
      </div>
    </CommonModal>
  );
};

export default inject('store')(observer(ModelsModal));
