/* eslint-disable no-console */
/* eslint-disable no-constant-condition */
// Mobx
import {
  ArchiveBoxXMarkIcon,
  ArrowDownTrayIcon,
  ArrowPathIcon,
  ArrowRightIcon,
  ClipboardDocumentIcon,
  ExclamationCircleIcon,
  PlusIcon,
  SparklesIcon,
  SpeakerWaveIcon,
  StarIcon as StarIconOutline,
  TrashIcon,
} from '@heroicons/react/24/outline';

import PencilSquareIcon from '../../../Theme/icons/editIcon';

import { UserCircleIcon } from '@heroicons/react/20/solid';

import { CssBaseline, IconButton } from '@mui/material';

import useClipboardComponent from 'Hooks/useClipboardComponent';
import { inject, observer } from 'mobx-react';
import { memo, useEffect, useRef, useState } from 'react';
import { toast } from 'react-hot-toast';
import ContentMessage from './ContentMessage';

import {
  ChevronDownIcon,
  CurrencyDollarIcon,
  InformationCircleIcon,
  StarIcon as StarIconSolid,
  XCircleIcon,
} from '@heroicons/react/24/solid';
import Button from '@mui/material/Button';
import useChatStore, { COLUMNS, GRID, ROWS, TABS } from 'Components/Chat/store';
import useChatMessageStore from 'Components/Common/Markdown/store';
import TextAreaEditMessage from 'Components/Common/Markdown/TextAreaEditMessage';
import { highlightWordsTextAreaV2 } from 'Constants/chat';
import ModelsIcon from '../../../Theme/icons/modelsIcon';
import { icons as modelIcons } from '../../../Theme/icons/modelsIcons/index';

import * as outlineIcons from '@heroicons/react/24/outline';
import { get } from 'lodash';
import useThemeStore from 'Theme/store';
import DeleteMessage from './DeleteMessage';

import { Tooltip } from '@mui/material';
import useTreeDNDChatStore from '../../Chat/TreeDNDChat/Store/index.js';
import DownloadOptionsDialog, {
  hasJSONStructure,
  hasTableStructure,
} from './DownloadFromMessage';
import PromptOptions from './PromptOptions';
import useCountCoinsEdit from './useCountCoinsEdit';

const getInitModel = (item) => {
  if (!Array.isArray(item)) {
    return item?.modelRef;
  }

  let model;
  if (Array.isArray(item) && item.some((m) => m.selected === true)) {
    let filter = item.filter((m) => m.selected === true);
    model = filter[0].modelRef;
  } else {
    model = item[0].modelRef;
  }
  return model;
};

const getInitFavorite = (item) => {
  if (!Array.isArray(item)) {
    return item?.is_favorite ?? false;
  }

  if (Array.isArray(item) && item.some((m) => m.selected === true)) {
    const selected = item.find((m) => m.selected === true);
    return selected?.is_favorite ?? false;
  }

  return item[0]?.is_favorite ?? false;
};

const truncateMessage = (message, searchTerm) => {
  if (typeof message !== 'string') {
    return message;
  }

  const parts = message.split(/(```[\s\S]*?```|`[^`]*`)/g);

  return parts
    .map((part) => {
      if (part.startsWith('```') || part.startsWith('`')) {
        return part;
      } else {
        return part.replace(
          new RegExp(`(${searchTerm})`, 'ig'),
          '<span style="color: orange; font-weight: bold;">$1</span>'
        );
      }
    })
    .join('');
};

const ChatMessage = inject('store')(
  observer(
    ({
      store,
      item,
      index,
      avatar,
      formatedDate,
      onDelete,
      onDeleteResponse,
      onCapturedScript,
      onLoading,
      onOpenFull,
      changeOutput,
      chatId,
      capabilities,
      onRegenerate,
      onRegenerateEdit,
      continueResponse,
      loading,
      optimizing,
      calculateCoinsEdit,
      onAddModels,
      fixRenderType,
      readOnlyMessage = false, // For favorite messages
      isWriting,
      onSend,
    }) => {
      const [localModels, setLocalModels] = useState(() => {
        const existing = localStorage.getItem('currentModels');
        return existing ? JSON.parse(existing) : [];
      });
      const [modelsAreLoading, setModelsAreLoading] = useState(true);

      const syncItemWithLocalModels = (originalItem, models) => {
        if (!Array.isArray(models)) return originalItem;

        if (!originalItem) return null;

        if (originalItem?.data?.role === 'user') {
          return originalItem;
        }

        if (
          typeof originalItem[0]?.data?.content === 'string' &&
          originalItem[0]?.data?.content.trim().length > 1
        ) {
          return originalItem;
        }

        if (Array.isArray(originalItem)) {
          const filteredItems = originalItem.filter((item) =>
            models.some((localModel) => localModel._id === item.modelRef)
          );

          const modelMap = new Map(
            models.map((localModel) => [localModel._id, localModel])
          );

          models.forEach((localModel) => {
            const existsInOriginal = filteredItems.some(
              (item) => item.modelRef === localModel._id
            );

            if (!existsInOriginal) {
              filteredItems.push({
                model: localModel.abbreviation || localModel.name,
                modelRef: localModel._id,
                data: {
                  role: 'assistant',
                  content: '',
                },
                finished: false,
              });
            }
          });

          filteredItems.sort((a, b) =>
            (a.model ?? '').localeCompare(b.model ?? '')
          );

          return filteredItems;
        }

        const isValidModel = models.some(
          (localModel) => localModel._id === originalItem.modelRef
        );

        return isValidModel ? originalItem : null;
      };

      useEffect(() => {
        async function fetchModelsIfEmpty() {
          if (!localModels.length) {
            try {
              const response = await store.api.get(`/model`);
              if (response.data?.models) {
                localStorage.setItem(
                  'currentModels',
                  JSON.stringify(response.data.models)
                );
                setLocalModels(response.data.models);
              }
            } catch (err) {
              toast.error('Failed to load models');
            }
          }
          setModelsAreLoading(false);
        }

        fetchModelsIfEmpty();
      }, [localModels.length, store.api]);

      const [finalItem, setFinalItem] = useState(() =>
        syncItemWithLocalModels(item, localModels)
      );

      useEffect(() => {
        setFinalItem(syncItemWithLocalModels(item, localModels));
      }, [item]);

      const [modelSelected, setModelSelected] = useState(getInitModel(item));

      const [editSelected, setEditSelected] = useState(false);

      const [editValue, setEditValue] = useState('');

      const [imagesPreview, setImagesPreview] = useState([]);

      const [hoveredIndex, setHoveredIndex] = useState(null);

      const readonlyTools = [
        '65805947d92e370a3d5625c6', // Dall-E 3
        '66c786d7e26442bc593e575d', // Flux Pro
      ];

      const [selectedModels, setSelectedModels] = useState([]);

      const [deleteTitle, setDeleteTitle] = useState(null);
      const [deleteMessage, setDeleteMessage] = useState(null);
      const [isDownloadDialogOpen, setIsDownloadDialogOpen] = useState(false);
      const [availableDownloadTypes, setAvailableDownloadTypes] = useState([]);
      const [deleteResponse, setDeleteResponse] = useState(false);

      const [currentEditMessages, setCurrentEditMessages] = useState([]);

      const [previousAiIndexResponse, setPreviousAiIndexResponse] = useState(0);

      const [editModelSelected, setEditModelSelected] = useState({});

      const [expandedIdx, setExpandedIdx] = useState([]);

      const chat = useChatStore((state) => state.selectedChat).chat;
      const modelsList = useChatStore((state) => state.modelsList);
      const setModelsList = useChatStore((state) => state.setModelsList);
      const renderTypeStore = useChatStore((state) => state.renderType);
      const powerPanelOpen = useChatStore((state) => state.powerPanelOpen);

      const [renderType, setRenderType] = useState(
        fixRenderType ? fixRenderType : renderTypeStore
      );

      const [newHeight, setNewHeight] = useState(null);
      const cardRefs = useRef([]);

      const addToRefs = (el) => {
        if (el && !cardRefs.current.includes(el)) {
          cardRefs.current.push(el);
        }
      };

      const chatContainerRef = useRef(null);

      const [isEditing, setIsEditing] = useState(false);

      const htmlToFormattedText = (html) => {
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = html;

        const processElement = (element) => {
          let text = '';
          element.childNodes.forEach((node) => {
            if (node.nodeType === Node.TEXT_NODE) {
              text += node.textContent;
            } else if (node.nodeType === Node.ELEMENT_NODE) {
              switch (node.tagName) {
                case 'BR':
                  text += '\n';
                  break;
                case 'P':
                case 'DIV':
                  text += processElement(node) + '\n\n';
                  break;
                case 'SPAN':
                case 'B':
                case 'I':
                case 'U':
                  text += processElement(node);
                  break;
                case 'PRE':
                  text += '\n' + node.textContent.trim() + '\n\n';
                  break;
                default:
                  text += processElement(node);
                  break;
              }
            }
          });
          return text;
        };

        let formattedText = processElement(tempDiv);

        formattedText = formattedText.replace(/\n{3,}/g, '\n\n');

        return formattedText.trim();
      };

      useEffect(() => {
        if (modelsList.length === 0) {
          getModels();
        }
      }, []);

      useEffect(() => {
        if (!fixRenderType) {
          setRenderType(renderTypeStore);
        }
      }, [renderTypeStore]);

      useEffect(() => {
        const cleanStyleContent = (clonedContent) => {
          const tempDiv = document.createElement('div');
          tempDiv.appendChild(clonedContent);

          // Remove style and script tags
          tempDiv
            .querySelectorAll('style, script')
            .forEach((node) => node.remove());

          // Remove inline style attributes
          tempDiv.querySelectorAll('*').forEach((node) => {
            node.removeAttribute('style');
          });

          return tempDiv.innerHTML;
        };

        const handleCopy = (e) => {
          if (isEditing) {
            e.preventDefault();
            return;
          }

          const selection = window.getSelection();
          if (
            selection.rangeCount > 0 &&
            chatContainerRef.current.contains(selection.anchorNode)
          ) {
            e.preventDefault();

            const range = selection.getRangeAt(0);
            const clonedContent = range.cloneContents();

            // Clean the HTML content while preserving semantic tags
            const cleanHTML = cleanStyleContent(clonedContent);

            // Create a temporary container to extract plain text
            const tempDiv = document.createElement('div');
            tempDiv.innerHTML = cleanHTML;

            const formattedText = htmlToFormattedText(tempDiv.innerHTML);

            e.clipboardData.setData('text/plain', formattedText);
            e.clipboardData.setData('text/html', cleanHTML);

            toast.custom(
              (t) => (
                <CustomToast
                  id={t.id}
                  message="Use the button to copy in Markdown format instead of highlighting the text."
                />
              ),
              { duration: 5000 }
            );

            toast.custom(
              (t) => (
                <CustomSuccessToast message="Copied to clipboard in HTML format" />
              ),
              { duration: 1500 }
            );
          }
        };

        document.addEventListener('copy', handleCopy);

        return () => {
          document.removeEventListener('copy', handleCopy);
        };
      }, [isEditing, chatContainerRef]);

      useEffect(() => {
        const [navigationEntry] = performance.getEntriesByType('navigation');

        if (navigationEntry.type === 'reload') {
          const wait = setInterval(() => {
            computeHeight();
            clearInterval(wait);
          }, 100);
        }
      }, []);

      useEffect(() => {
        computeHeight();
      }, [item, renderType, powerPanelOpen]);

      const computeHeight = () => {
        setNewHeight(null);

        if (Array.isArray(item) && cardRefs.current.length === item.length) {
          const heights = cardRefs.current.map((ref) => ref.clientHeight);
          const shortestHeight = Math.min(...heights);
          const longestHeight = Math.max(...heights);
          const gap = longestHeight - shortestHeight;
          const adjustedHeight = shortestHeight + 0.3 * gap;

          if (gap > 0.75 * shortestHeight) {
            const adjustedHeight = shortestHeight + 0.3 * gap;
            if (adjustedHeight > 190) {
              setNewHeight(adjustedHeight);
            }
          } else {
            setNewHeight(300);
          }
        }
      };

      // Check for Download options
      useEffect(() => {
        const checkDownloadTypes = (content) => {
          const types = [];
          if (hasTableStructure(content)) types.push('csv');
          if (hasJSONStructure(content)) types.push('json');
          return types;
        };
        // Handle multiple model responses
        if (Array.isArray(item)) {
          const allTypes = item.reduce((types, modelResponse) => {
            if (modelResponse?.data?.content) {
              types.push(...checkDownloadTypes(modelResponse?.data?.content));
            }
            return types;
          }, []);
          setAvailableDownloadTypes([...new Set(allTypes)]);
        } else if (item?.data?.content) {
          // Handle single response
          setAvailableDownloadTypes(checkDownloadTypes(item?.data?.content));
        }
      }, [item]);

      // Hook to count words
      const { words } = useCountCoinsEdit({
        text: editValue,
        messages: currentEditMessages,
        editModelSelected: editModelSelected,
        editSelected: editSelected,
      });

      const { editMessChange } = useChatMessageStore();

      const { copy } = useClipboardComponent();

      const [showDeleteMessageDialog, setShowDeleteMessageDialog] =
        useState(false);

      const [messageToDelete, setMessageToDelete] = useState(null);

      const [deleted, setDeleted] = useState(false);

      const [favorite, setFavorite] = useState(false);

      const handleCopy = (item) => {
        try {
          let rawText = Array.isArray(item?.data?.content)
            ? item?.data?.content
                .filter((obj) => obj.type === 'text')
                .map((obj) => obj.text)
                .join('\n')
            : item?.data?.content;
          copy(rawText);
          toast.custom(
            (t) => (
              <CustomToast
                id={t.id}
                message="To copy in HTML format, highlight the text you want to copy and then use your standard copy function."
              />
            ),
            { duration: 5000 }
          );
          toast.custom(
            (t) => (
              <CustomSuccessToast message="Copied to clipboard in markdown forma" />
            ),
            { duration: 1500 }
          );
        } catch (error) {
          toast.error('Error copying to clipboard');
        }
      };

      const { theme: themeValue } = useThemeStore();

      const addReferenceFile = async (e) => {
        onLoading(true);
        try {
          toast.loading(
            "Adding your image to attachments - find it under the '+' icon once it's ready",
            {
              id: 'loading-save-file',
            }
          );
          await store.api.post(`/file`, e);
          toast.dismiss('loading-save-file');
          toast.success('File added to your attachments');
          onLoading(false);
        } catch (error) {
          if (error?.response?.data?.error?.message) {
            toast.error(error?.response?.data?.error?.message);
          }
          onLoading(false);
        }
      };

      const handleMessageForTTS = (value) => {
        if (onCapturedScript) {
          let rawText = Array.isArray(value.data.content)
            ? value.data.content
                .filter((obj) => obj.type === 'text')
                .map((obj) => obj.text)
                .join('\n')
            : value.data.content;

          onCapturedScript(rawText);
        }
      };

      const handleDelete = (messageId, isResponse = false) => {
        if (isResponse) {
          setDeleteTitle('Delete response');
          setDeleteMessage('Are you sure that you wish to delete this answer?');
          setDeleteResponse(true);
        }
        // Implement the logic to delete the message
        setMessageToDelete(messageId);
        setShowDeleteMessageDialog(true);
      };

      const conversationId = chatId || null;

      const handleConfirmDelete = async () => {
        if (deleteResponse) {
          await handleDeleteResponse();
        } else {
          await handleDeleteMessage();
        }
      };

      const handleDeleteMessage = async () => {
        try {
          const response = await store.api.delete(
            `/chat/${chatId}/${messageToDelete}`
          );
          if (response.data.success) {
            // Removing the message from the state
            onDelete(messageToDelete);
            setShowDeleteMessageDialog(false);

            toast.success('Message has been deleted.');
          } else {
            console.error(
              'Failed to delete the message:',
              response.data.message
            );
            toast.error('Failed to delete the message.');
          }
        } catch (error) {
          console.error('Error delete message:', error);
          toast.error('Error deleting message.');
        } finally {
          setDeleteTitle(null);
          setDeleteMessage(null);
          setDeleteResponse(false);
        }
      };

      const handleDeleteResponse = async () => {
        try {
          const response = await store.api.delete(
            `/chat/response/${chatId}/${messageToDelete}`
          );
          if (response.data.success) {
            // Removing the response from the state
            onDeleteResponse(messageToDelete);
            setShowDeleteMessageDialog(false);

            toast.success('Response has been deleted.');
          } else {
            console.error(
              'Failed to delete the response:',
              response.data.message
            );
            toast.error('Failed to delete the response.');
          }
        } catch (error) {
          console.error('Error delete response:', error);
          toast.error('Error deleting response.');
        }
      };

      const handleChange = (e) => {
        setEditValue(e);
      };

      const isReadOnly = (item) => {
        return readonlyTools.includes(item?.tool);
      };

      const handleEditPrompt = (value) => {
        if (loading)
          return toast('Wait a moment, the chat is being processed', {
            icon: '📢',
          });

        let prompt = '';
        if (Array.isArray(value.data.content)) {
          prompt = value.data.content
            .filter((obj) => obj.type === 'text')
            .map((obj) => obj.text)
            .join('\n');
        } else {
          prompt = value.data.content;
        }

        let images = [];
        if (Array.isArray(value.data.content)) {
          images = value.data.content.filter((obj) => obj.type === 'image_url');
        }

        let currentMessages = [...chat.messages];

        let selectedMessageIndex = currentMessages.findIndex(
          (message) => message.date === value.date
        );

        let aiIndexResponse = selectedMessageIndex - 1;

        currentMessages = currentMessages.slice(aiIndexResponse + 2);

        setPrevSelected(selectedMessageIndex + 1);
        setPreviousAiIndexResponse(selectedMessageIndex + 1);
        setCurrentEditMessages(currentMessages);
        setImagesPreview(images);
        setEditValue(prompt);
        setIsEditing(true);
        setEditSelected(true);
      };

      const setPrevSelected = (prevAiIndResp) => {
        let refId;
        let lastMsg = chat.messages[prevAiIndResp];
        if (lastMsg) {
          let filterd = Array.isArray(lastMsg)
            ? lastMsg.filter((m) => m.selected === true)
            : null;
          refId =
            filterd && filterd.length
              ? filterd[0].modelRef
                ? filterd[0].modelRef
                : null
              : lastMsg.modelRef
              ? lastMsg.modelRef
              : null;
        }

        let models = JSON.parse(localStorage.getItem('currentModels'));
        setSelectedModels(models);

        let filteredModels =
          refId && models
            ? models.filter((model) => model._id === refId)
            : null;
        let filterModel =
          filteredModels && filteredModels.length
            ? filteredModels[0]
            : models && models.length
            ? models[0]
            : null;
        setEditModelSelected(filterModel);
        return filterModel;
      };

      useEffect(() => {
        setPrevSelected(previousAiIndexResponse);
      }, [words, editMessChange]);

      const handleMouseEnter = (index) => {
        setHoveredIndex(index);
      };

      const handleMouseLeave = () => {
        setHoveredIndex(null);
      };

      const removeImage = (img) => {
        let modified = imagesPreview.filter(
          (item) => JSON.stringify(item) !== JSON.stringify(img)
        );
        setImagesPreview([...modified]);
      };

      const handleSend = (value) => {
        if (!editValue || editValue.trim() === '') {
          toast("Don't forget: Messages need text too!", {
            icon: '😱',
          });
          return;
        }

        if (!selectedModels.some((model) => model.type === 'vision')) {
          if (imagesPreview.length > 0) {
            return toast(
              "Can't edit messages with images in this Model (LLM)",
              {
                icon: '📢',
              }
            );
          }
        }

        const chatCopy = JSON.parse(JSON.stringify(chat));

        setEditSelected(false);
        setIsEditing(false);
        onRegenerateEdit(
          editValue,
          value.date,
          chat,
          imagesPreview,
          words,
          chatCopy
        );
      };

      const handleCancel = () => {
        setEditSelected(false);
        setIsEditing(false);
      };

      const handleToggleFavorite = async (element, marked) => {
        try {
          const idChat = chat._id ? chat._id : chatId;

          const response = await store.api.put(
            `/chat/${idChat}/message/toggle-mark`,
            {
              isUser: isUser(),
              messageId: isUser() ? null : element._id, // _id for AI messages
              messageDate: isUser() ? element.date : null, // date for user messages
              marked: marked,
            }
          );

          if (response.data.success) {
            // If the message is an array, mark all unique elements
            if (Array.isArray(item)) {
              for (let unique of item) {
                unique.is_favorite = marked;
              }
            } else {
              // If the message is a single element, we just mark it
              element.is_favorite = marked;
            }
            setFavorite(marked);

            if (marked) {
              toast.success(
                'Message starred! This message is now in your Favorite Messages section'
              );
            } else {
              toast.success('Message removed from favorites');
            }
          } else {
            if (marked) {
              toast.error('Failed adding message to favorites');
            } else {
              toast.error('Failed removing message from favorites');
            }
          }
        } catch (error) {
          if (marked) {
            toast.error('Error adding message to favorites');
          } else {
            toast.error('Error removing message from favorites');
          }
        }
      };

      // Set the favorite state when loading is done
      useEffect(() => {
        if (!loading) {
          setFavorite(getInitFavorite(item));
        }
      }, [loading]);

      const isUser = () => {
        return !Array.isArray(item) && item?.data?.role === 'user';
      };

      const getTabs = (item) => {
        return Array.isArray(item)
          ? item.map((ele) => ({
              ...ele,
              isWriting:
                ele?.data?.role !== 'user' &&
                !ele?.finished &&
                ele?.contentLength > 2,
            }))
          : [
              {
                ...item,
                isWriting:
                  item?.data?.role !== 'user' &&
                  !item?.finished &&
                  item?.contentLength > 2,
              },
            ];
      };

      const selectedOutputReadOnly = async (element) => {
        const hash = element._id;
        if (hash) {
          await store.api.post(`/chat/message/${hash}`, {
            chat: chatId,
          });
        }
      };

      const handleOutput = async (element, index) => {
        if (!Array.isArray(item)) {
          return;
        }

        for (let i = 0; i < item.length; i++) {
          item[i].selected = i === index ? true : false;
        }

        setModelSelected(element.modelRef);
        setFavorite(element?.is_favorite ?? false);

        if (readOnlyMessage) {
          await selectedOutputReadOnly(element);
        } else {
          changeOutput(element);
        }
      };

      const isSelected = (ele) => {
        if (
          !Array.isArray(item) ||
          (ele._id && ele.selected === true && !loading) ||
          (!ele._id && ele.selected === true && loading)
        ) {
          return true;
        }

        let model = modelSelected ? modelSelected : getInitModel(item);
        return model === ele.modelRef;
      };

      const { search } = useTreeDNDChatStore();

      const isExpanded = (idx) => {
        if (renderType === TABS || isUser()) {
          return true;
        }

        return (
          (window.innerWidth < 768 && expandedIdx.includes(idx)) ||
          window.innerWidth >= 768
        );
      };

      const ChatBubbleContent = (item, deleted, idx, topButtons = false) => {
        let hasSearchTerm = false;
        // THIS VALIDATION IS FOR THE CASE WHEN THE MESSAGE IS AN ARRAY (IMAGES RESPONSES)
        if (typeof item?.data?.content === 'string') {
          hasSearchTerm =
            item?.data?.role !== 'user' &&
            search &&
            item?.data?.content?.toLowerCase().includes(search?.toLowerCase());
        } else if (Array.isArray(item?.data?.content)) {
          hasSearchTerm =
            item?.data?.role !== 'user' &&
            search &&
            item?.data?.content.some((content) => {
              if (content.type === 'text') {
                return content.text
                  .toLowerCase()
                  .includes(search.toLowerCase());
              }
              return false;
            });
        }

        return (
          <>
            {!readonlyTools.includes(item?.tool) &&
              getCapabilityState(item?.data?.function, item)}
            <div className={`flex w-full ${hasSearchTerm ? 'highlight' : ''}`}>
              {!editSelected ? (
                <div
                  ref={addToRefs}
                  className={`w-full ${
                    !topButtons && 'md:w-[calc(100%-2.5rem)]'
                  } overflow-hidden mt-2`}
                >
                  <ContentMessage
                    message={
                      item?.data?.role !== 'user' && search
                        ? truncateMessage(item?.data?.content, search)
                        : item?.data?.content
                    }
                    userType={item?.data?.role}
                    onFavoriteFile={addReferenceFile}
                    onOpenFull={onOpenFull}
                    originalPrompt={item?.data?.originalPrompt}
                    expanded={isExpanded(idx)}
                    optimizing={optimizing}
                    images={item?.imagesURL?.map((image) => ({
                      ...image,
                      loading: loading,
                    }))}
                    store={store}
                    renderedFromShareChatPage={false}
                    isEditable={false}
                    onSend={onSend}
                    loading={loading}
                    capability={capability}
                    functionCapability={item?.data?.function}
                  />
                </div>
              ) : (
                <div className="w-full">
                  <div className="mt-3">
                    <TextAreaEditMessage
                      value={editValue}
                      onChange={handleChange}
                      placeholder=""
                      classNameContainer={` outline-none focus:outline-none text-md bg-white rounded w-full font-regular ${
                        false ? 'border-red-400' : 'border-cool-gray'
                      } font-figtree text-raisin-black shadow-none text-[16px]`}
                      classNameMainContainer={'bg-white'}
                      autoFocus
                      minRows={1}
                      maxRows={9}
                      highlightWords={highlightWordsTextAreaV2}
                    >
                      <div className="flex gap-3 px-[0.625rem]">
                        {imagesPreview.length > 0 &&
                          imagesPreview.map((image, index) => (
                            <div
                              key={index}
                              className="mt-3 relative cursor-pointer"
                              onMouseEnter={() => handleMouseEnter(index)}
                              onMouseLeave={handleMouseLeave}
                            >
                              <img
                                src={image.image_url.url}
                                alt={'Preview-' + index}
                                className="w-16 h-16 rounded object-cover"
                              />
                              <div
                                className={`absolute -top-[0.625rem] -right-[0.625rem] ${
                                  hoveredIndex === index
                                    ? 'visible'
                                    : 'invisible'
                                }`}
                              >
                                <XCircleIcon
                                  className={`w-5 h-5 ${
                                    themeValue === 'dark'
                                      ? 'dark:text-quicksilver'
                                      : 'text-cool-gray'
                                  }`}
                                  onClick={() => removeImage(image)}
                                />
                              </div>
                            </div>
                          ))}
                      </div>
                    </TextAreaEditMessage>
                  </div>

                  {selectedModels.length > 0 && (
                    <div className="flex gap-1 mt-1 font-figtree text-xs items-center justify-end">
                      <div
                        className={`${
                          themeValue === 'dark'
                            ? 'dark:text-sonic-silver '
                            : 'text-cool-gray '
                        } font-light`}
                      >
                        <span
                          className={`${
                            words > editModelSelected.words_text &&
                            'text-red-600'
                          }`}
                        >
                          {words.toLocaleString('en-US')}
                        </span>
                        /{editModelSelected.words_text.toLocaleString('en-US')}
                      </div>
                      <a
                        href="https://straico.com/multimodel/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div className="rounded-full cursor-pointer relative group flex flex-col items-center group text-cool-gray dark:text-sonic-silver">
                          <InformationCircleIcon className="w-4 h-4" />
                        </div>
                      </a>
                      <div className="flex flex-row items-center px-1 bg-cool-gray dark:bg-sonic-silver rounded-[20px] text-white font-semibold p-[2px]">
                        <CurrencyDollarIcon className="w-4 h-4" />
                        <span className="mx-1">
                          {calculateCoinsEdit(words, imagesPreview)}
                        </span>
                      </div>
                    </div>
                  )}
                  <div className="flex justify-end space-x-2 mt-3 my-1">
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={handleCancel}
                      sx={{ fontSize: '0.75rem' }}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleSend(item)}
                      sx={{ fontSize: '0.75rem' }}
                    >
                      Send
                    </Button>
                  </div>
                </div>
              )}
            </div>
            {loading && <div className="h-8"></div>}
            {!topButtons && (
              <div className="w-full flex items-end justify-start">
                {getButtons(item, topButtons)}
              </div>
            )}
            <DeleteMessage
              open={showDeleteMessageDialog}
              onClose={() => setShowDeleteMessageDialog(false)}
              onDelete={handleConfirmDelete}
              deleted={deleted}
              setDeleted={setDeleted}
              title={deleteTitle}
              message={deleteMessage}
            />
          </>
        );
      };

      const getButtons = (item, sideBySide) => {
        return (
          <>
            {!editSelected && (
              <>
                {!readOnlyMessage && !loading && (
                  <Tooltip title="Text to speech" arrow>
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        handleMessageForTTS(item);
                      }}
                    >
                      <SpeakerWaveIcon className="h-4 w-4 text-battleship-gray" />
                    </IconButton>
                  </Tooltip>
                )}

                {!readOnlyMessage &&
                  !loading &&
                  availableDownloadTypes?.length > 0 && (
                    <Tooltip
                      title={`Download as ${availableDownloadTypes.join(
                        ' or '
                      )}`}
                      arrow
                    >
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation();
                          setIsDownloadDialogOpen(true);
                        }}
                      >
                        <ArrowDownTrayIcon className="h-4 w-4 text-battleship-gray" />
                      </IconButton>
                    </Tooltip>
                  )}
                {isDownloadDialogOpen && (
                  <DownloadOptionsDialog
                    open={isDownloadDialogOpen}
                    onClose={() => setIsDownloadDialogOpen(false)}
                    message={item?.data}
                    api={store?.api}
                    availableTypes={availableDownloadTypes}
                  />
                )}

                {!loading && (
                  <Tooltip title="Copy markdown" arrow>
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        handleCopy(item);
                      }}
                    >
                      <ClipboardDocumentIcon className="h-4 w-4 text-battleship-gray" />
                    </IconButton>
                  </Tooltip>
                )}

                {!readOnlyMessage &&
                  isUser() &&
                  !isReadOnly(item) &&
                  !loading && (
                    <Tooltip title="Edit message" arrow>
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation();
                          handleEditPrompt(item);
                        }}
                      >
                        <span className="flex items-center justify-center h-4 w-4">
                          <PencilSquareIcon
                            className="text-battleship-gray"
                            strokeWidth={1.3}
                            style={{ width: '14px', height: '14px' }}
                          />
                        </span>
                      </IconButton>
                    </Tooltip>
                  )}

                {!readOnlyMessage && item?._id && !loading && !sideBySide && (
                  <Tooltip title="Delete message" arrow>
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDelete(item?._id);
                      }}
                    >
                      <TrashIcon className="h-4 w-4 text-battleship-gray" />
                    </IconButton>
                  </Tooltip>
                )}

                {!readOnlyMessage &&
                  item._id &&
                  !loading &&
                  sideBySide &&
                  showDeleteResponse() && (
                    <Tooltip title="Delete response" arrow>
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDelete(item._id, true);
                        }}
                      >
                        <ArchiveBoxXMarkIcon className="h-4 w-4 text-battleship-gray" />
                      </IconButton>
                    </Tooltip>
                  )}

                {/* Mark as favorite */}
                {!readOnlyMessage &&
                  isSelected(item) &&
                  !favorite &&
                  !loading && (
                    <Tooltip title="Add to Favorities" arrow>
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation();
                          handleToggleFavorite(item, true);
                        }}
                      >
                        <StarIconOutline className="h-4 w-4 text-battleship-gray" />
                      </IconButton>
                    </Tooltip>
                  )}

                {/* Unmark as favorite */}
                {!readOnlyMessage &&
                  isSelected(item) &&
                  favorite &&
                  !loading && (
                    <Tooltip title="Remove from Favorities" arrow>
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation();
                          handleToggleFavorite(item, false);
                        }}
                      >
                        <StarIconSolid
                          className={`h-4 w-4 ${
                            themeValue === 'dark'
                              ? 'dark:text-yellow-400'
                              : 'text-yellow-500'
                          }`}
                        />
                      </IconButton>
                    </Tooltip>
                  )}

                {!sideBySide && !readOnlyMessage && !loading && index === 0 && (
                  <>
                    <Tooltip title="Regenerate response" arrow>
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation();
                          onRegenerate();
                        }}
                      >
                        <ArrowPathIcon className="h-4 w-4 text-battleship-gray" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Continue response" arrow>
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation();
                          continueResponse();
                        }}
                      >
                        <ArrowRightIcon className="h-4 w-4 text-battleship-gray" />
                      </IconButton>
                    </Tooltip>
                  </>
                )}

                {item?.data?.originalPrompt && !loading && (
                  <div className="absolute bottom-2 right-10">
                    <PromptOptions
                      store={store}
                      loading={loading}
                      originalPrompt={item?.data?.originalPrompt}
                      optimizedPrompt={item?.data?.content}
                      basePrompt={item?.data?.basePrompt}
                      systemInstructions={item?.data?.systemInstructions}
                      onUndoOptimization={(e) => {
                        e.stopPropagation();
                        onRegenerateEdit(
                          item?.data?.originalPrompt,
                          item?.date,
                          chat,
                          [],
                          item?.data?.originalPrompt.split(/\s+/).length,
                          JSON.parse(JSON.stringify(chat)),
                          true
                        );
                      }}
                      conversationId={conversationId}
                    />
                  </div>
                )}
              </>
            )}
          </>
        );
      };

      const showDeleteResponse = () => {
        if (!Array.isArray(item)) return false;

        const filterArray = item.map((res) => res.hidden !== true);
        return filterArray.length > 1;
      };

      const getIcon = (icon, className = 'h-6 w-6') => {
        const Icon = get(outlineIcons, icon);
        return Icon ? <Icon className={className} /> : null;
      };

      let capability = null;

      const compatibleModels = [
        '66ba974277a570110a9f1259',
        '66998fd4f394ef4982b23d18',
        '650c9cd8430d61e033694664',
        '6642a33334aaf100dea04eca',
      ];

      const [localCapabilities, setLocalCapabilities] = useState(() => {
        const savedCapabilities = localStorage.getItem('capabilities');
        return savedCapabilities ? JSON.parse(savedCapabilities) : [];
      });

      const [isLabelExpanded, setIsLabelExpanded] = useState(false);

      const getCapabilityState = (func, item) => {
        if (!item) return;

        if (!capabilities || !Array.isArray(capabilities)) {
          return;
        }
        if (isReadOnly(item)) {
          return;
        }
        if (!localCapabilities || localCapabilities.length === 0) {
          return null;
        }
        if (func) {
          const capability = capabilities.find(
            (cap) => cap._id === func.capability
          );

          return (
            capability && (
              <div className="flex rounded py-1 px-2 mt-2 gap-2 bg-lavender text-violet-blue dark:text-tropical-indigo dark:bg-ship-grey w-fit text-xs items-center">
                {getIcon(capability.icon, 'h-4 w-4')}
                <span>{capability.label_states[func.state]}</span>
              </div>
            )
          );
        } else if (!func && item?.data?.role !== 'user' && !isReadOnly(item)) {
          const isCompatible = compatibleModels.includes(item?.modelRef);
          const storedCapabilitiesString = localStorage.getItem('capabilities');

          const storedCapabilities = storedCapabilitiesString
            ? JSON.parse(storedCapabilitiesString)
            : [];

          const incompatibleCapabilities = storedCapabilities.filter(
            (storedCap) =>
              capabilities.some((cap) => cap.name === storedCap.name)
          );

          if (!isCompatible && incompatibleCapabilities.length > 0) {
            const incompatibleNames = incompatibleCapabilities
              .map((cap) => cap.name)
              .join(', ');
            return (
              <div className="flex rounded py-1 px-2 mt-2 gap-2 bg-lavender text-violet-blue dark:text-tropical-indigo dark:bg-ship-grey w-fit text-xs items-center">
                <ExclamationCircleIcon className="h-4 w-4" />
                <div className="flex items-center gap-2">
                  <span>
                    {isLabelExpanded
                      ? `The current model isn't compatible with the selected capabilities: ${incompatibleNames}`
                      : `The current model isn't compatible with the selected capabilities...`}
                  </span>
                  <ChevronDownIcon
                    onClick={() => setIsLabelExpanded(!isLabelExpanded)}
                    className={`h-4 w-4 cursor-pointer transition-transform ${
                      isLabelExpanded ? 'rotate-180' : ''
                    }`}
                  />
                </div>
              </div>
            );
          } else if (isCompatible) {
            if (item?.data?.content !== null && item?.imagesURL?.length === 0) {
              return;
            }
            return (
              <div className="flex rounded py-1 px-2 mt-2 gap-2 bg-lavender text-violet-blue dark:text-tropical-indigo dark:bg-ship-grey w-fit text-xs items-center">
                <ArrowPathIcon className="h-4 w-4" />
                <span>Checking capabilities calls...</span>
              </div>
            );
          }

          return (
            <div className="flex rounded py-1 px-2 mt-2 gap-2 bg-lavender text-violet-blue dark:text-tropical-indigo dark:bg-ship-grey w-fit text-xs items-center">
              <SparklesIcon className="h-4 w-4" />
              <span>
                It looks like you attempted to use capabilities with this model,
                but it's not compatible
              </span>
            </div>
          );
        }
      };

      const isHidden = (item) => {
        return Array.isArray(item)
          ? item.some((ele) => ele?.hidden)
          : item?.hidden;
      };

      const checkCapability = (ele) => {
        if (!ele?.data?.function) {
          return;
        }

        const capability = capabilities.find(
          (cap) => cap._id === ele?.data?.function?.capability
        );

        return (
          capability && (
            <div className="group">
              {getIcon(capability.icon, 'h-4 h-4')}
              <Tooltip className="hidden absolute font-medium flex-col items-center group-hover:flex left-[calc(100%_-_0.5rem)] top-1/2">
                <div className="p-2 text-sm leading-none text-raisin-black bg-lavender bg-opacity-25 shadow-lg text-center backdrop-filter backdrop-blur rounded-md">
                  Capability:{' '}
                  <span className="font-bold">{capability.name}</span>
                </div>
              </Tooltip>
            </div>
          )
        );
      };

      const getModelIcon = () => {
        let currentModel;
        if (!Array.isArray(item)) {
          currentModel = item;
        } else {
          currentModel = modelSelected
            ? item.find((e) => e.modelRef === modelSelected)
            : item.find((e) => e.selected);
        }
        //"Dall-E 3"
        if (currentModel && currentModel.model === 'Dall-E 3') {
          const gptIcon = modelsList.find(
            (m) => m.model === 'gpt-4o-2024-08-06'
          );
          const GptIconComponent =
            gptIcon && gptIcon.icon ? get(modelIcons, gptIcon.icon) : null;
          return GptIconComponent ? (
            <GptIconComponent className="h-10 w-10" />
          ) : (
            <ModelsIcon className="h-10 w-10 stroke-cool-gray" />
          );
        }

        const icon = currentModel
          ? modelsList.find((m) => m._id === currentModel.modelRef)
          : null;
        const Icon = icon && icon.icon ? get(modelIcons, icon.icon) : null;
        return Icon ? (
          <Icon className="h-10 w-10" />
        ) : (
          <ModelsIcon className="h-10 w-10 stroke-cool-gray" />
        );
      };

      const getModels = async () => {
        try {
          const response = await store.api.get(`/model`);
          setModelsList(response.data.models);
        } catch (error) {
          console.error(error);
        }
      };

      const getContentMessage = () => {
        if (isUser()) return defaultView();
        switch (renderType) {
          case COLUMNS:
          case ROWS:
          case GRID:
            return sideBySideView();
          default:
            return defaultView();
        }
      };

      const getGridCols = () => {
        switch (renderType) {
          case COLUMNS:
            return 'grid-cols-' + item?.length;
          case GRID:
            return item?.length >= 2 ? 'grid-cols-2' : '';
          default:
            return 'grid-cols-1';
        }
      };

      const isOdd = () => {
        return item.length % 2 !== 0;
      };

      const defaultView = () => {
        const skeletonStyle = (width, height, delay) => {
          // Lógica para determinar el color y el gradiente según el valor del tema.
          const gradient =
            themeValue === 'dark'
              ? 'linear-gradient(100deg, #474549 20%, rgba(37, 36, 37, 0) 50%, #474549 60%)'
              : 'linear-gradient(100deg, #e8e8e8 20%, rgba(247, 247, 248, 0) 50%, #e8e8e8 60%)';
          const backgroundColor =
            themeValue === 'dark'
              ? 'rgba(37, 36, 37, 0)'
              : 'rgba(247, 247, 248, 0)';

          return {
            width,
            height,
            borderRadius: '2px',
            display: 'inline-block',
            backgroundColor,
            backgroundSize: '1000px 1000px',
            backgroundImage: gradient,
            animation: `springIn 0.8s cubic-bezier(0.34, 1.56, 0.64, 1) ${delay} forwards, placeholderShimmer 1.5s linear infinite ${delay}`,
          };
        };

        return (
          <div
            className={`pb-1 pt-0 ${
              index !== 0 && renderType === TABS && 'border-b border-cool-gray'
            }`}
          >
            <div className="w-full flex pt-2 justify-between">
              <div className="flex flex-1 flex-wrap gap-2">
                {getTabs(item).map((ele, idx) => (
                  <div className="relative" key={idx}>
                    {ele?.data?.role !== 'user' && (
                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                          isSelected(ele) ? null : handleOutput(ele, idx);
                        }}
                        className={`${
                          ele?.finished === false && 'relative'
                        } overflow-hidden w-fit capitalize rounded-md px-1 text-[12px] ${
                          isSelected(ele)
                            ? 'bg-nue-blue text-white'
                            : 'bg-platinum text-cool-gray dark:bg-tornado-cloud dark:text-powder-blue'
                        } font-bold cursor-pointer select-none flex gap-1 items-center`}
                      >
                        <p>{ele?.model}</p>
                        {checkCapability(ele)}
                        {ele?.finished === false && (
                          <div
                            className={`absolute inset-0 transform -translate-x-full bg-gradient-to-r ${
                              isSelected(ele)
                                ? 'from-white to-white via-lavender'
                                : 'from-ship-grey to-nue-blue via-violet-blue'
                            } animate-flash`}
                          ></div>
                        )}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
            <div className="chat-container">
              <div ref={chatContainerRef} className="w-full">
                {getTabs(item).map((ele, index) => (
                  <div key={index}>
                    {isSelected(ele) && (
                      <>
                        {ChatBubbleContent(ele, deleted, index)}
                        {ele.finished === false &&
                          ele?.data?.role !== 'user' && (
                            <div
                              className="max-w-full w-full"
                              style={{ borderColor: '#5256A6' }}
                            >
                              <div className="flex-1 space-y-4">
                                <div className="flex gap-4">
                                  <span
                                    className="skeleton"
                                    style={skeletonStyle('60%', '10px', '0s')}
                                  ></span>
                                  <span
                                    className="skeleton"
                                    style={skeletonStyle('40%', '10px', '0.3s')}
                                  ></span>
                                </div>
                                <span
                                  className="skeleton"
                                  style={skeletonStyle('80%', '10px', '0.6s')}
                                ></span>
                              </div>
                            </div>
                          )}
                      </>
                    )}
                  </div>
                ))}
              </div>
            </div>

            <style>
              {`
    .latex-block {
      display: block;
      margin: 1em 0;
      text-align: center;
      overflow-x: auto; /* Permitir scroll horizontal si es necesario. */
    }

    .latex-inline {
      display: inline;
      margin: 0;
      overflow-x: visible; /* Ajustar al texto circundante. */
    }

    @keyframes springIn {
      0% {
        transform: scaleX(0);
        transform-origin: left;
      }
      60% {
        transform: scaleX(1.2);
        transform-origin: left;
      }
      100% {
        transform: scaleX(1);
        transform-origin: left;
      }
    }

    @keyframes placeholderShimmer {
      0% {
        background-position: -500px 0;
      }
      100% {
        background-position: 500px 0;
      }
    }
  `}
            </style>
          </div>
        );
      };

      const sideBySideView = () => {
        const skeletonStyle = (width, height, delay) => {
          const gradient =
            themeValue === 'dark'
              ? 'linear-gradient(100deg, #474549 20%, rgba(37, 36, 37, 0) 50%, #474549 60%)'
              : 'linear-gradient(100deg, #e8e8e8 20%, rgba(247, 247, 248, 0) 50%, #e8e8e8 60%)';
          const backgroundColor =
            themeValue === 'dark'
              ? 'rgba(37, 36, 37, 0)'
              : 'rgba(247, 247, 248, 0)';

          return {
            width,
            height,
            borderRadius: '2px',
            display: 'inline-block',
            backgroundColor,
            backgroundSize: '1000px 1000px',
            backgroundImage: gradient,
            animation: `springIn 0.8s cubic-bezier(0.34, 1.56, 0.64, 1) ${delay} forwards, placeholderShimmer 1.5s linear infinite ${delay}`,
          };
        };

        return (
          <div className="pb-1 pt-0">
            <div className="chat-container">
              <div
                ref={chatContainerRef}
                className={`w-full grid gap-5 ${getGridCols()} p-4`}
                style={{
                  overflowX: 'auto',
                  whiteSpace: 'normal',
                }}
              >
                {getTabs(item).map((ele, index) => (
                  <div
                    key={index}
                    style={newHeight ? { height: `${newHeight}px` } : {}}
                    className={`flex flex-col felx-grow px-4 py-5 ${
                      themeValue === 'dark' ? 'bg-night-black' : 'bg-white'
                    } rounded-[22px] ${
                      renderType === GRID &&
                      index === item?.length - 1 &&
                      isOdd() &&
                      'col-span-2'
                    } ${
                      isSelected(ele) && 'border-[3px] border-nue-blue'
                    } shadow-[4px_4px_10px_0px_rgba(0,0,0,0.10)] overflow-auto mb-4`}
                  >
                    {/* Tabs */}
                    <div className="relative">
                      {ele?.data?.role !== 'user' && (
                        <div
                          className="flex items-center justify-between flex-wrap cursor-pointer select-none"
                          onClick={(e) => {
                            e.stopPropagation();
                            isSelected(ele) ? null : handleOutput(ele, index);
                          }}
                        >
                          <div
                            className={`${
                              ele?.finished === false && 'relative'
                            } overflow-hidden w-fit capitalize rounded-md px-2 text-[12px] ${
                              isSelected(ele)
                                ? 'bg-nue-blue text-white'
                                : 'bg-platinum text-cool-gray dark:bg-tornado-cloud dark:text-powder-blue'
                            } font-semibold flex whitespace-nowrap gap-1 items-center mx-auto md:mx-0`}
                          >
                            <p>{ele?.model}</p>
                            {checkCapability(ele)}
                            {ele?.finished === false && (
                              <div
                                className={`absolute inset-0 transform -translate-x-full bg-gradient-to-r ${
                                  isSelected(ele)
                                    ? 'from-white to-white via-lavender'
                                    : 'from-ship-grey to-nue-blue via-violet-blue'
                                } animate-flash`}
                              ></div>
                            )}
                          </div>
                          <div className="hidden md:block">
                            {getButtons(ele, true)}
                          </div>
                          <div className="block md:hidden">
                            <IconButton onClick={() => handleExpanded(index)}>
                              <ChevronDownIcon
                                className={`h-4 w-4 text-battleship-gray duration-500 ${
                                  expandedIdx.includes(index) &&
                                  'rotate-[180deg]'
                                }`}
                              />
                            </IconButton>
                          </div>
                        </div>
                      )}
                    </div>
                    {/* Content */}
                    <div
                      className={`flex-1 overflow-auto overflow-rtl ${
                        themeValue === 'dark'
                          ? 'overflow-rtl-dark'
                          : 'overflow-rtl-light'
                      }`}
                      style={{ overflowX: 'hidden' }}
                    >
                      {ChatBubbleContent(ele, deleted, index, true)}
                      {ele.finished === false && (
                        <div
                          className="max-w-full w-full"
                          style={{ borderColor: '#5256A6' }}
                        >
                          <div className="flex-1 space-y-4">
                            <div className="flex gap-4">
                              <span
                                className="skeleton"
                                style={skeletonStyle('60%', '10px', '0s')}
                              ></span>
                              <span
                                className="skeleton"
                                style={skeletonStyle('40%', '10px', '0.3s')}
                              ></span>
                            </div>
                            <span
                              className="skeleton"
                              style={skeletonStyle('80%', '10px', '0.6s')}
                            ></span>
                          </div>
                        </div>
                      )}
                    </div>
                    {expandedIdx.includes(index) && (
                      <div className="flex md:hidden justify-center">
                        {getButtons(ele, true)}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>

            <style>
              {`
                @keyframes springIn {
                  0% {
                    transform: scaleX(0);
                    transform-origin: left;
                  }
                  60% {
                    transform: scaleX(1.2);
                    transform-origin: left;
                  }
                  100% {
                    transform: scaleX(1);
                    transform-origin: left;
                  }
                }

                @keyframes placeholderShimmer {
                  0% {
                    background-position: -500px 0;
                  }
                  100% {
                    background-position: 500px 0;
                  }
                }
              `}
            </style>
          </div>
        );
      };

      const handleExpanded = (idx) => {
        setExpandedIdx((prevNumbers) => {
          if (prevNumbers.includes(idx)) {
            return prevNumbers.filter((n) => n !== idx);
          } else {
            return [...prevNumbers, idx];
          }
        });
      };

      const centerMessage = (item) => {
        return (
          renderType === TABS ||
          renderType === ROWS ||
          isUser() ||
          !Array.isArray(item) ||
          (Array.isArray(item) && item?.length === 1)
        );
      };

      const fullModels = (item) => {
        return item?.length === 4;
      };

      const getLastMessageButtons = () => {
        return (
          <>
            <Tooltip title="Regenerate response" placement="right" arrow>
              <div
                className="flex items-center justify-center w-[34px] h-[34px] bg-nue-blue hover:bg-nue-blue/70 cursor-pointer select-none rounded-xl"
                onClick={(e) => {
                  e.stopPropagation();
                  onRegenerate();
                }}
              >
                <ArrowPathIcon className="h-5 w-5 text-white" />
              </div>
            </Tooltip>

            <Tooltip title="Continue response" placement="right" arrow>
              <div
                className="flex items-center justify-center w-[34px] h-[34px] bg-nue-blue hover:bg-nue-blue/70 cursor-pointer select-none rounded-xl"
                onClick={(e) => {
                  e.stopPropagation();
                  continueResponse();
                }}
              >
                <ArrowRightIcon className="h-5 w-5 text-white" />
              </div>
            </Tooltip>

            <Tooltip title="Add response" placement="right" arrow>
              <div
                className={`flex items-center justify-center w-[34px] h-[34px] rounded-xl select-none ${
                  fullModels(item)
                    ? 'bg-platinum dark:bg-tornado-cloud'
                    : 'bg-nue-blue hover:bg-nue-blue/70 cursor-pointer'
                }`}
                onClick={() => (fullModels(item) ? null : onAddModels())}
              >
                <PlusIcon
                  className={`h-5 w-5 ${
                    fullModels(item)
                      ? 'text-cool-gray dark:text-palladium'
                      : 'text-white'
                  }`}
                />
              </div>
            </Tooltip>
          </>
        );
      };

      return (
        <>
          {!isHidden(item) && (
            <div
              className={`flex gap-[4px] w-full ${
                centerMessage(item) && 'lg:max-w-4xl m-auto'
              }`}
            >
              <div className="hidden md:block">
                {/* AI Robot */}
                {!isUser() && (
                  <div className="h-min relative group flex flex-col items-center group">
                    <div className={renderType === TABS && 'avatar'}>
                      <div className="w-12">
                        {renderType === TABS ? (
                          <div className="w-full h-full flex items-center justify-center">
                            {getModelIcon()}
                          </div>
                        ) : (
                          <>
                            {index === 0 && !loading && (
                              <div className="py-2 flex flex-col gap-4 w-min m-auto">
                                {getLastMessageButtons()}
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                )}
                {/* Person - avatar */}
                {isUser() && (
                  <div className="h-min relative group flex flex-col items-center group">
                    {avatar ? (
                      <div className="avatar">
                        <div className="w-12">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: avatar,
                            }}
                          />
                        </div>
                      </div>
                    ) : (
                      <UserCircleIcon
                        className={`w-12 h-12 ${
                          themeValue === 'dark'
                            ? 'text-tornado-cloud'
                            : 'text-cool-gray'
                        }`}
                      />
                    )}
                  </div>
                )}
              </div>
              <div
                key={index}
                className={`w-full relative pb-2 lg:pb-2 pr-4 pl-4 md:pl-0 pt-2 text-md font-figtree text-base font-normal ${
                  themeValue === 'dark'
                    ? 'text-crystal-bell'
                    : 'text-raisin-black'
                }`}
              >
                <CssBaseline />
                {getContentMessage()}
                {index === 0 && (
                  <div className="flex md:hidden gap-4 mx-auto justify-center">
                    {!loading ? (
                      getLastMessageButtons()
                    ) : (
                      <div className="h-[34px]"></div>
                    )}
                  </div>
                )}
              </div>
            </div>
          )}
        </>
      );
    }
  )
);

const CustomToast = ({ id, message }) => {
  const [isExiting, setIsExiting] = useState(false);
  const { theme: themeValue } = useThemeStore();

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsExiting(true);
    }, 4500);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div
      className={`${isExiting ? 'toast-exit' : ''}`}
      style={{
        display: 'flex',
        alignItems: 'center',
        backgroundColor: themeValue === 'dark' ? '#333' : '#fff',
        color: themeValue === 'dark' ? '#fff' : '#333',
        padding: '8px 16px',
        borderRadius: '8px',
        transition: 'opacity 0.5s ease, transform 0.5s ease',
        boxShadow:
          themeValue === 'dark'
            ? '0 4px 8px rgba(0, 0, 0, 0.7)'
            : '0 4px 8px rgba(0, 0, 0, 0.1)',
      }}
    >
      <span>📖 {message}</span>
      <button
        onClick={() => {
          setIsExiting(true);
          setTimeout(() => toast.dismiss(id), 500);
        }}
        style={{
          marginLeft: '16px',
          background: 'transparent',
          border: 'none',
          cursor: 'pointer',
          color: themeValue === 'dark' ? '#fff' : '#333',
          fontSize: '12px',
          padding: '0',
          lineHeight: '1',
        }}
      >
        ✖️
      </button>
    </div>
  );
};

const CustomSuccessToast = ({ message }) => {
  const { theme: themeValue } = useThemeStore();

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        backgroundColor: themeValue === 'dark' ? '#333' : '#fff',
        color: themeValue === 'dark' ? '#fff' : '#333',
        padding: '8px 16px',
        borderRadius: '8px',
        boxShadow:
          themeValue === 'dark'
            ? '0 4px 8px rgba(0, 0, 0, 0.7)'
            : '0 4px 8px rgba(0, 0, 0, 0.1)',
        transition: 'opacity 0.2s ease, transform 0.2s ease',
      }}
    >
      <span>📖 {message}</span>
    </div>
  );
};

export default memo(ChatMessage);
