import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';
import {
  Bars2Icon,
  TrashIcon,
  ChevronDownIcon,
} from '@heroicons/react/24/outline';
import toast from 'react-hot-toast';
import EditIcon from '../../../Theme/icons/editIcon';
import useModelsAndCapabilities from '../../../Features/ModelsModal/hooks/useModelsAndCapabilities';
import useChatStore from '../../Chat/store';
import ModelsModal from '../../../Features/ModelsModal';

import { MAX_MODELS_SELECTION } from 'Constants/models';

const LLMExistingCombos = ({ id, title, store, theme }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [tempTitle, setTempTitle] = useState(title);
  const [validModels, setValidModels] = useState([]);
  const [showModelsDialog, setShowModelsDialog] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const {
    setModelsList,

    selectedCombo,
    updateSelectedModels,
    isDefaultCombo,
    deleteCombo,
    setIsEditingCombo,
    setSelectedCombo,
    combos,
    fetchCombos,
  } = useChatStore((state) => state);

  const { capabilities } = useModelsAndCapabilities({
    store,
    setModelsList,
  });

  const sortByAttribute = (arr, attribute) => {
    return arr?.sort((a, b) => {
      if (a[attribute] < b[attribute]) {
        return -1;
      }
      if (a[attribute] > b[attribute]) {
        return 1;
      }
      return 0;
    });
  };

  const openDeleteDialog = () => {
    setIsDeleteDialogOpen(true);
  };

  const closeDeleteDialog = () => {
    setIsDeleteDialogOpen(false);
  };

  const confirmDelete = async () => {
    try {
      fetchCombos();
      await deleteCombo(id);
      setTransformedCombos((prevCombos) =>
        prevCombos.filter((combo) => combo.id !== id)
      );
      closeDeleteDialog();
    } catch (error) {
      console.error('Error deleting combo:', error);
    }
  };

  const setSelectedModels = (models) => {
    updateSelectedModels(() => sortByAttribute(models, 'abbreviation'));
    localStorage.setItem('currentModels', JSON.stringify(models));
  };

  const handleDelete = async () => {
    try {
      fetchCombos();
      await deleteCombo(id);
      setTransformedCombos((prevCombos) =>
        prevCombos.filter((combo) => combo.id !== id)
      );
    } catch (error) {
      console.error('Error deleting combo:', error);
    }
  };

  const handleEdit = () => {
    setIsEditingCombo(true);
    fetchCombos();

    setSelectedModels(validModels);
    setSelectedCombo({
      _id: id,
      name: tempTitle,
      llms: validModels.map((model) => model._id),
      isDefault: isDefaultCombo(id),
    });
    setIsEditing(true);
  };

  const handleTitleChange = (event) => {
    setTempTitle(event.target.value);
  };

  const [errorMessage, setErrorMessage] = useState('');

  const handleSaveEdit = async () => {
    // Same validations from handleCreateCombo:
    const trimmedValue = tempTitle.trim();

    // 1) Empty name check
    if (!trimmedValue) {
      setErrorMessage('The combo name cannot be empty.');
      toast.error('The combo name cannot be empty.');
      return;
    }

    // 2) Disallow "Custom Combination"
    const invalidName = 'Custom Combination';
    if (trimmedValue.toLowerCase() === invalidName.toLowerCase()) {
      setErrorMessage(`The name "${invalidName}" is not allowed.`);
      toast.error(`The name "${invalidName}" is not allowed.`);
      return;
    }

    // 3) Max length check
    const maxComboNameLength = 15;
    if (trimmedValue.length > maxComboNameLength) {
      setErrorMessage(
        `The name cannot exceed ${maxComboNameLength} characters.`
      );
      toast.error(`The name cannot exceed ${maxComboNameLength} characters.`);
      return;
    }

    // 4) Duplicate name check (excluding current combo's own name)
    const isDuplicateName = combos.some(
      (combo) =>
        combo.name.toLowerCase() === trimmedValue.toLowerCase() &&
        combo._id !== id
    );
    if (isDuplicateName) {
      setErrorMessage('A combo with this name already exists.');
      toast.error('A combo with this name already exists.');
      return;
    }

    // 5) Duplicate models check (excluding current combo's own models)
    const selectedModelIds = validModels.map((model) => model._id).sort();
    const isDuplicateModels = combos.some((combo) => {
      const comboModelIds = (combo.llms || []).sort();
      return (
        combo._id !== id &&
        comboModelIds.length === selectedModelIds.length &&
        comboModelIds.every((mId, idx) => mId === selectedModelIds[idx])
      );
    });
    if (isDuplicateModels) {
      setErrorMessage('A combo with the same models already exists.');
      toast.error('A combo with the same models already exists.');
      return;
    }

    // 6) Ensure at least one model is selected
    if (validModels.length === 0) {
      setErrorMessage('You must select at least one model.');
      toast.error('You must select at least one model.');
      return;
    }

    // Clear any existing error
    setErrorMessage('');

    // If all validations pass, proceed with update:
    try {
      await store.api.put(`/combos/llm-combos/${id}`, {
        name: trimmedValue,
        llms: validModels.map((model) => model._id),
      });
      toast.success('Combo updated successfully!');
      setSelectedCombo({
        _id: id,
        name: trimmedValue,
        llms: validModels.map((model) => model._id),
        isDefault: isDefaultCombo(id),
      });
    } catch (error) {
      console.error('Error updating combo:', error);
      toast.error('Failed to update combo.');
    } finally {
      setIsEditing(false);
      setIsEditingCombo(false);
      fetchCombos();
    }
  };

  const editBoxRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (editBoxRef.current && !editBoxRef.current.contains(event.target)) {
        setIsEditing(false); // Salir del modo de edición
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleEditClick = () => {
    setIsEditing(true); // Entrar al modo de edición
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSaveEdit(); // Llama a handleSaveEdit para guardar los cambios
    } else if (event.key === 'Escape') {
      setTempTitle(title); // Restaura el valor original
      setIsEditing(false); // Cierra el modo de edición
    }
  };

  const [availableModels, setAvailableModels] = useState([]);
  const [transformedCombos, setTransformedCombos] = useState([]);

  const getModels = async () => {
    try {
      const response = await store.api.get(`/model`);
      const models = response.data.models;
      setAvailableModels(models);
    } catch (error) {
      console.error('Error fetching models:', error);
    }
  };

  useEffect(() => {
    getModels();
  }, []);

  useEffect(() => {
    const fetchCombos = async () => {
      try {
        const response = await store.api.get('/combos/llm-combos');
        if (response.status === 200) {
          const rawCombos = response.data.combos || [];
          const transformed = rawCombos.map((combo) => {
            const modelsForCombo = combo.llms.map((id) => {
              return availableModels.find(
                (model) => String(model._id) === String(id)
              );
            });

            const validModelsForCombo = modelsForCombo
              .filter(Boolean)
              .map((model) => ({
                ...model,
                provider: model.platform || 'Unknown',
                model: model.abbreviation || model.name || 'Unknown Model',
              }));

            return {
              id: combo._id,
              title: combo.name || 'Untitled Combo',
              models: validModelsForCombo,
              isDefault: combo.isDefault,
            };
          });

          setTransformedCombos(transformed);
          const currentCombo = transformed.find((combo) => combo.id === id);
          setValidModels(currentCombo ? currentCombo.models : []);
        }
      } catch (error) {
        console.error('Error fetching combos:', error);
      }
    };

    if (availableModels.length > 0) {
      fetchCombos();
    }
  }, [availableModels]);

  const handleSelectModelWithValidModels = async (model) => {
    try {
      const filteredValidModels = validModels.filter(
        (item) => item && item._id
      );

      if (filteredValidModels.some((item) => item._id === model._id)) {
        const updatedModels = filteredValidModels.filter(
          (item) => item._id !== model._id
        );

        if (updatedModels.length > 0) {
          await store.api.put(`/combos/llm-combos/${selectedCombo._id}`, {
            llms: updatedModels.map((item) => item._id),
          });

          setValidModels(updatedModels);
          setSelectedModels(updatedModels);
        } else {
          toast('You must have at least one model selected', {
            icon: '⚠️',
          });
        }
      } else {
        if (filteredValidModels.length < MAX_MODELS_SELECTION) {
          const updatedModels = [...filteredValidModels, model];
          await store.api.put(`/combos/llm-combos/${selectedCombo._id}`, {
            llms: updatedModels.map((item) => item._id),
          });

          setValidModels(updatedModels);
          setSelectedModels(updatedModels);
        } else {
          toast('You only can select up to 4 LLMs simultaneously', {
            icon: '⚠️',
          });
        }
      }
    } catch (error) {
      console.error('Error updating models for the combo:', error);
      toast('Failed to update models. Please try again.', {
        icon: '❌',
      });
    }
  };
  const setComboAndShowDialog = async (
    comboId,
    comboName,
    comboModels,
    isDefault
  ) => {
    setIsEditingCombo(true);

    try {
      const response = await store.api.get(`/model`);
      const allModels = response.data.models;

      const updatedModels = comboModels
        .map((model) => allModels.find((m) => m._id === model._id))
        .filter(Boolean)
        .sort((a, b) => a.abbreviation.localeCompare(b.abbreviation));

      setSelectedCombo({
        _id: comboId,
        name: comboName,
        llms: comboModels.map((model) => model._id),
        isDefault: isDefault,
      });

      setSelectedModels(updatedModels);
      setShowModelsDialog(true);

      localStorage.setItem('currentModels', JSON.stringify(updatedModels));
    } catch (error) {
      console.error('Error updating selected models:', error);
    }
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          gap: '10px',
          backgroundColor: 'transparent',
          padding: '8px 0',
        }}
        className="editable-input"
      >
        <Bars2Icon
          style={{
            width: '24px',
            height: '24px',
            color: theme === 'dark' ? '#858585' : '#9091A4',
          }}
        />

        <Box sx={{ width: '22px', height: '22px' }}>
          <EditIcon
            style={{
              width: '22px',
              height: '22px',
              color: theme === 'dark' ? '#858585' : '#9091A4',
              cursor: !isDefaultCombo(id) ? 'pointer' : 'default',
              opacity: isDefaultCombo(id) ? 0 : 1,
              pointerEvents: isDefaultCombo(id) ? 'none' : 'auto',
            }}
            onClick={!isDefaultCombo(id) ? handleEdit : undefined}
          />
        </Box>

        <Box
          sx={{
            flexGrow: 1,
            display: 'flex',
            maxWidth: '10%',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {isEditing && !isDefaultCombo(id) ? (
            <input
              autoFocus
              value={tempTitle}
              onChange={(e) => setTempTitle(e.target.value)}
              onKeyDown={handleKeyDown}
              style={{
                fontSize: '14px',
                maxWidth: '100%',
                fontFamily: 'Figtree',
                fontWeight: 700,
                padding: 0,
                margin: 0,
                color: theme === 'dark' ? '#EFEFEF' : '#211E21',
                textAlign: 'center',
                border: theme === 'dark' ? '#9091A461' : '#9091A461',
                backgroundColor: theme === 'dark' ? '#39383A' : '#FBFBFA',
              }}
            />
          ) : (
            <Typography
              onClick={handleEdit}
              sx={{
                fontSize: '14px',
                maxWidth: '100%',
                fontFamily: 'Figtree',
                fontWeight: 700,
                color: theme === 'dark' ? '#FFFFFF' : '#211E21',
                textAlign: 'center',
                lineHeight: 1.5,
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
              }}
            >
              {tempTitle}
            </Typography>
          )}
        </Box>

        <Box
          onClick={() =>
            setComboAndShowDialog(
              id,
              tempTitle,
              validModels,
              isDefaultCombo(id)
            )
          }
          sx={{
            flexGrow: 1,
            maxWidth: '75%',
            minHeight: '51px',
            backgroundColor: theme === 'dark' ? '#39383A' : '#FBFBFA',
            borderRadius: '10px',
            border: `1.5px solid ${
              theme === 'dark' ? '#9091A461' : '#9091A461'
            }`,
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            padding: '8px 12px',
            cursor: 'pointer',
            gap: '8px',
            position: 'relative',
          }}
        >
          {validModels.length > 0 ? (
            validModels.map((model, index) => (
              <Box
                key={index}
                sx={{
                  backgroundColor: theme === 'dark' ? '#787878' : '#EDEDF2',
                  borderRadius: '24px',
                  padding: '2px 12px',
                  maxWidth: '80%',
                  fontSize: '12px',
                  color: theme === 'dark' ? '#EFEFEF' : '#51526C',
                  fontFamily: 'Figtree',
                  fontWeight: 500,
                  whiteSpace: 'nowrap',
                }}
              >
                {model.name}
              </Box>
            ))
          ) : (
            <Typography
              sx={{
                fontSize: '12px',
                color: theme === 'dark' ? '#FFFFFF' : '#9091A4',
              }}
            >
              Loading models....
            </Typography>
          )}
          <ChevronDownIcon
            style={{
              width: '18px',
              height: '18px',
              color: theme === 'dark' ? '#FFFFFF' : '#9091A4',
              position: 'absolute',
              top: '50%',
              right: '12px',
              transform: 'translateY(-50%)',
              fontWeight: 700,
            }}
          />
        </Box>

        <Box sx={{ width: '24px', height: '24px' }}>
          <TrashIcon
            style={{
              width: '24px',
              height: '24px',
              color: theme === 'dark' ? '#FFFFFF' : '#9091A4',
              cursor: !isDefaultCombo(id) ? 'pointer' : 'default',
              opacity: isDefaultCombo(id) ? 0 : 1,
              pointerEvents: isDefaultCombo(id) ? 'none' : 'auto',
            }}
            onClick={!isDefaultCombo(id) ? openDeleteDialog : undefined}
          />
        </Box>
      </Box>
      <Dialog
        open={isDeleteDialogOpen}
        onClose={closeDeleteDialog}
        PaperProps={{
          style: {
            borderRadius: '21px',
            backgroundColor: theme === 'dark' ? '#252425' : '#F7F7F8',
            width: '90%',
            maxWidth: '400px',
            height: 'auto',
            maxHeight: '90vh',
            padding: '16px',
            overflowY: 'auto',
          },
          elevation: 0,
        }}
      >
        <DialogTitle
          sx={{
            textAlign: 'center',
            color: theme === 'dark' ? '#EFEFEF' : '#211E21',
            fontWeight: 700,
            fontFamily: 'Figtree, sans-serif',
            fontSize: '24px',
            lineHeight: '28px',
            '@media (max-width:600px)': {
              fontSize: '20px',
              lineHeight: '24px',
            },
          }}
        >
          Delete combo
        </DialogTitle>
        <DialogContent
          sx={{
            paddingBottom: '1px',
            textAlign: 'center',
            '@media (max-width:600px)': {
              padding: '8px 12px',
            },
          }}
        >
          Are you sure you want to delete this combo?
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: 'center',
            marginTop: '-8px',
            gap: '36%',
            '@media (max-width:600px)': {
              marginTop: '8px',
              gap: '16px',
              flexWrap: 'wrap',
            },
          }}
        >
          <Button
            onClick={closeDeleteDialog}
            sx={{
              backgroundColor: theme === 'dark' ? '#3B3B45' : '#D4D5E6',
              color: theme === 'dark' ? '#7F82C3' : '#5256A6',
              textTransform: 'none',
              display: 'flex',
              alignItems: 'center',
              gap: '4px',
              '&:hover': {
                backgroundColor: theme === 'dark' ? '#34343E' : '#C7C9DE',
              },
              '@media (max-width:600px)': {
                fontSize: '14px',
                padding: '6px 12px',
                width: '100%',
              },
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={confirmDelete}
            sx={{
              backgroundColor: '#464BBA',
              color: '#FFFFFF',
              textTransform: 'none',
              display: 'flex',
              alignItems: 'center',
              gap: '4px',
              '&:hover': {
                backgroundColor: '#3B40A0',
              },
              '@media (max-width:600px)': {
                fontSize: '14px',
                padding: '6px 12px',
                width: '100%',
              },
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <ModelsModal
        open={showModelsDialog}
        selectedModels={validModels}
        selectedCapabilities={capabilities}
        handleCombos={handleSelectModelWithValidModels}
        onClose={() => setShowModelsDialog(false)}
        nocapabilities={true}
        themeValue={theme}
      />
    </>
  );
};

export default LLMExistingCombos;
