import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import { css, keyframes } from '@emotion/react';
import { PlusCircleIcon } from '@heroicons/react/24/outline';
import LLMComboCreate from './LLMCombosBars/LLMComboCreate';
import toast from 'react-hot-toast';

const slideInFromTop = keyframes`
  from {
    opacity: 0;
    transform: translateY(-30px);
    filter: blur(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
    filter: blur(0);
  }
`;

const LLMCombos = ({ store, options, theme, allModels }) => {
  const [rows, setRows] = useState([]);

  const addRow = () => {
    if (rows.length > 0) {
      toast('You are already creating a combo.', {
        icon: '⚠️',
        style: {
          borderRadius: '10px',
          background: '#fff',
          color: '#333',
        },
      });
      return;
    }

    setRows([...rows, { id: Date.now() }]);
  };

  const deleteRow = (id) => {
    setRows(rows.filter((row) => row.id !== id));
  };

  return (
    <Box>
      <Box
        onClick={addRow}
        sx={{
          height: '42px',
          borderRadius: '12px',
          backgroundColor: theme === 'dark' ? '#3B3B45' : '#D4D5E6',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '10px',
          cursor: 'pointer',
        }}
      >
        <PlusCircleIcon
          style={{
            width: '22px',
            height: '22px',
            color: theme === 'dark' ? '#7F82C3' : '#5256A6',
          }}
        />
        <Typography
          sx={{
            fontSize: '14px',
            fontWeight: '600',
            lineHeight: '26px',
            fontFamily: 'Figtree',
            color: theme === 'dark' ? '#7F82C3' : '#5256A6',
          }}
        >
          Add New Combination
        </Typography>
      </Box>

      {rows.map((row) => (
        <Box
          key={row.id}
          css={css`
            animation: ${slideInFromTop} 0.6s ease-out;
          `}
        >
          <LLMComboCreate
            store={store}
            onDelete={() => deleteRow(row.id)}
            models={options}
            onSave={() => console.log('Save combo')}
            theme={theme}
            allModels={allModels}
          />
        </Box>
      ))}
    </Box>
  );
};

export default LLMCombos;
