import {
  InformationCircleIcon,
  ListBulletIcon,
  MagnifyingGlassIcon,
  RectangleGroupIcon,
  StarIcon,
  BookmarkSquareIcon as BookmarkSquareIconOutline,
} from '@heroicons/react/24/outline';
import {
  AdjustmentsHorizontalIcon,
  ArrowsUpDownIcon,
} from '@heroicons/react/24/solid';
import {
  Badge,
  Divider,
  Popover,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  DialogActions,
} from '@mui/material';
import BadgeInformation from 'Features/ModelsModal/components/BadgeInformation';
import BlurTooltip from 'Features/ModelsModal/components/blurTooltip';
import FilterButton from 'Features/ModelsModal/components/filterButton';
import { useSelectedFiltersCount } from 'Features/ModelsModal/hooks/useSelectedFiltersCount';
import {
  APPLICATIONS_MAP,
  CAPABILITIES_MAP,
  FEATURES_MAP,
  OTHERS_MAP,
} from 'Features/ModelsModal/mocks/constants';
import { sortItems } from 'Features/ModelsModal/mocks/itemsSort';
import useModelsModalStore from 'Features/ModelsModal/store';
import { getSortIcon } from 'Features/ModelsModal/utils';
import usePopoverState from 'Hooks/usePopoverState';
import { useCallback, useState } from 'react';
import { CrownIcon } from 'Theme/icons/crown';
import useChatStore from '../../../Components/Chat/store';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';

const FiltersContainer = ({
  nocapabilities,
  selectedModels,
  handleSelectModel,
  models,
  themeValue,
}) => {
  // State from the Models Modal Store
  const state = useModelsModalStore();
  const {
    search,
    setSearch,
    layout,
    setLayout,
    sort,
    setSort,
    favorites,
    other: others,
    editorsChoice,
    features,
    capabilities,
    applications,
    toggleFavorites,
    toggleEditorsChoice,
    toggleFilter,
    clearAll,
  } = state;
  const countFilters = useSelectedFiltersCount(state);

  // State from the Chat Store (for combos)
  const {
    updateSelectedModels,
    setSelectedCombo,
    selectedCombo,
    combos,
    createCombo,
  } = useChatStore((chatState) => ({
    updateSelectedModels: chatState.updateSelectedModels,
    setSelectedCombo: chatState.setSelectedCombo,
    selectedCombo: chatState.selectedCombo,
    combos: chatState.combos,
    createCombo: chatState.createCombo,
  }));

  // Check if there's an existing combo with the same LLM IDs as the current selection
  const matchingCombo = combos.find((combo) => {
    const comboIds = (combo.llms || []).slice().sort();
    const selectedIds = selectedModels
      .map((m) => m._id)
      .slice()
      .sort();

    return (
      comboIds.length === selectedIds.length &&
      comboIds.every((id, index) => id === selectedIds[index])
    );
  });

  // Search handling
  const handleChangeSearch = useCallback(
    (value) => setSearch(value),
    [setSearch]
  );
  const onChangeSearch = (e) => {
    handleChangeSearch(e.target.value);
  };

  // Layout handling
  const handleChangeLayout = (event, newLayout) => {
    setLayout(newLayout);
  };

  // Handle selecting a saved combo
  const handleComboClick = async (combo) => {
    setSelectedCombo(combo);

    if (combo && Array.isArray(combo.llms)) {
      try {
        const selectedModels = combo.llms
          .map((llmId) => models.find((model) => model._id === llmId))
          .filter(Boolean)
          .sort((a, b) => a.abbreviation.localeCompare(b.abbreviation));

        updateSelectedModels(() => selectedModels);
        localStorage.setItem('currentModels', JSON.stringify(selectedModels));
      } catch (error) {
        console.error('Error processing combo:', error);
      }
    }
  };

  // Dialog for saving a new combo
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [comboName, setComboName] = useState('');
  const [error, setError] = useState('');
  const maxComboNameLength = 15;
  const invalidName = 'Custom Combination';

  const validateComboName = (name) => {
    const trimmedName = name.trim();

    if (!trimmedName) {
      return 'The combo name cannot be empty.';
    }
    if (trimmedName.toLowerCase() === invalidName.toLowerCase()) {
      return `The name "${invalidName}" is not allowed.`;
    }
    if (trimmedName.length > maxComboNameLength) {
      return `The name cannot exceed ${maxComboNameLength} characters.`;
    }
    const isDuplicateName = combos.some(
      (combo) => combo.name.toLowerCase() === trimmedName.toLowerCase()
    );
    if (isDuplicateName) {
      return 'A combo with this name already exists.';
    }

    return null;
  };

  const handleInputChange = (event) => {
    const name = event.target.value;
    setComboName(name);
    const validationError = validateComboName(name);
    setError(validationError);
  };

  const handleSaveClick = () => {
    // Use existing combo name if selected, otherwise default
    setComboName(selectedCombo?.name || 'Custom Combination');
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
    setComboName('');
    setError('');
  };

  const handleSaveCombo = async () => {
    const trimmedName = comboName.trim();

    // Validation
    if (!trimmedName) {
      setError('The combo name cannot be empty.');
      return;
    }
    if (trimmedName.toLowerCase() === invalidName.toLowerCase()) {
      setError(`The name "${invalidName}" is not allowed.`);
      return;
    }
    if (trimmedName.length > maxComboNameLength) {
      setError(`The name cannot exceed ${maxComboNameLength} characters.`);
      return;
    }
    const isDuplicateName = combos.some(
      (combo) => combo.name.toLowerCase() === trimmedName.toLowerCase()
    );
    if (isDuplicateName) {
      setError('A combo with this name already exists.');
      return;
    }

    // Create the new combo
    setError('');
    try {
      const newComboData = {
        name: trimmedName,
        llms: selectedModels.map((model) => model._id),
      };
      const newCombo = await createCombo(newComboData);
      setSelectedCombo(newCombo);
      setIsDialogOpen(false);
    } catch (error) {
      console.error('Failed to save combo:', error);
      setError('Failed to create combo.');
    }
  };

  // Popover states
  const filterPopover = usePopoverState('filter-popover');
  const sortPopover = usePopoverState('sort-popover');

  return (
    <>
      {/* Dialog for saving combos */}
      <Dialog
        open={isDialogOpen}
        onClose={handleDialogClose}
        PaperProps={{
          style: {
            borderRadius: '21px',
            backgroundColor: themeValue === 'dark' ? '#252425' : '#F7F7F8',
            width: '90%',
            maxWidth: '400px',
            height: 'auto',
          },
          elevation: 0,
        }}
      >
        <DialogTitle
          sx={{
            textAlign: 'center',
            color: themeValue === 'dark' ? '#EFEFEF' : '#211E21',
            fontWeight: 700,
            fontFamily: 'Figtree, sans-serif',
            fontSize: '24px',
            lineHeight: '28px',
            '@media (max-width:600px)': {
              fontSize: '20px',
            },
          }}
        >
          Save New LLM Combination
        </DialogTitle>
        <DialogContent
          sx={{
            paddingBottom: '1px',
            '@media (max-width:600px)': {
              padding: '8px',
            },
          }}
        >
          <TextField
            autoFocus
            margin="dense"
            label="Custom Combination Name"
            type="text"
            fullWidth
            variant="outlined"
            placeholder="Custom Combination"
            value={comboName}
            onChange={handleInputChange}
            error={Boolean(error)}
            helperText={error}
            InputProps={{
              style: {
                borderRadius: '12px',
                padding: 0,
                backgroundColor: themeValue === 'dark' ? '#323232' : '#FBFBFA',
              },
            }}
          />
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: 'center',
            marginTop: '-8px',
            '@media (max-width:600px)': {
              marginTop: '0px',
            },
            gap: '36%',
          }}
        >
          <Button
            onClick={handleDialogClose}
            sx={{
              backgroundColor: themeValue === 'dark' ? '#3B3B45' : '#D4D5E6',
              color: themeValue === 'dark' ? '#7F82C3' : '#5256A6',
              textTransform: 'none',
              display: 'flex',
              alignItems: 'center',
              gap: '4px',
              '&:hover': {
                backgroundColor: themeValue === 'dark' ? '#34343E' : '#C7C9DE',
              },
              '@media (max-width:600px)': {
                fontSize: '14px',
                padding: '6px 12px',
              },
            }}
          >
            <CloseIcon /> Cancel
          </Button>
          <Button
            onClick={handleSaveCombo}
            sx={{
              backgroundColor: '#464BBA',
              color: '#FFFFFF',
              textTransform: 'none',
              display: 'flex',
              alignItems: 'center',
              gap: '4px',
              '&:hover': {
                backgroundColor: '#3B40A0',
              },
              '@media (max-width:600px)': {
                fontSize: '14px',
                padding: '6px 12px',
              },
            }}
          >
            <CheckIcon /> Save
          </Button>
        </DialogActions>
      </Dialog>

      {/* Combination Selector and Selected Models */}
      <div className="flex justify-center items-center mt-3 flex-col md:flex-row gap-2 md:gap-0">
        {!nocapabilities && (
          <div className="flex items-center mr-4">
            <span className="text-sm font-medium text-gray-700 dark:text-[#EFEFEF] mr-2">
              Current combination:
            </span>
            <select
              className={`px-3 py-1 text-sm rounded-xl border ${
                themeValue === 'dark'
                  ? 'border-gray-600 bg-[#39383A] text-[#EFEFEF]'
                  : 'border-gray-300 bg-white text-gray-700'
              }`}
              value={matchingCombo ? matchingCombo._id : ''}
              onChange={(e) => {
                const combo = combos.find((c) => c._id === e.target.value);
                handleComboClick(combo);
              }}
            >
              <option value="" disabled>
                Custom Combination
              </option>
              {combos.map((combo) => (
                <option key={combo._id} value={combo._id}>
                  {combo.name}
                </option>
              ))}
            </select>
            {(!matchingCombo ||
              selectedCombo?.name === 'Custom Combination') && (
              <Tooltip title="Save New Combo" arrow placement="right">
                <div
                  className="w-[34px] h-[34px] bg-lavender hover:bg-ghost-white cursor-pointer rounded-[10px] dark:bg-ship-grey dark:hover:bg-quicksilver justify-center items-center flex select-none ml-2"
                  onClick={handleSaveClick}
                >
                  <BookmarkSquareIconOutline className="w-5 h-5 text-violet-blue dark:text-tropical-indigo" />
                </div>
              </Tooltip>
            )}
          </div>
        )}
        <div className="max-w-[100]">
          <ul className="flex flex-wrap justify-center gap-2">
            {selectedModels.map((model) => (
              <li
                key={model._id}
                className={`flex items-center px-3 py-1 rounded-full border text-xs ${
                  themeValue === 'dark'
                    ? 'bg-[#39383A] text-[#B0B0B0] border-gray-600'
                    : 'bg-white text-gray-700 border-gray-300'
                }`}
              >
                {model.name}
                <button
                  className="ml-2 text-gray-500 hover:text-red-500 dark:hover:text-red-400"
                  onClick={() => handleSelectModel(model)}
                >
                  ✕
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>

      {/* Search + Filters + Sort */}
      <div className="flex justify-between items-center mt-3 flex-col md:flex-row gap-2 md:gap-0">
        <div className="flex-1 max-w-[500px]">
          <div className="mx-auto">
            <div className="relative">
              <MagnifyingGlassIcon className="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
              <input
                type="text"
                placeholder="Search"
                value={search}
                onChange={onChangeSearch}
                className="w-full pl-12 pr-3 py-1 rounded-xl border border-cool-gray shadow-sm focus:outline-none focus:ring-2 focus:ring-nue-blue
                  bg-transparent dark:bg-night-black focus:border-transparent transition-all text-gray-600 dark:text-crystal-bell placeholder-gray-400"
              />
            </div>
          </div>
        </div>
        <div className="flex gap-3">
          {/* Filters */}
          <Badge badgeContent={countFilters} color="primary">
            <FilterButton
              text="Filters"
              onClick={filterPopover.handleClick}
              aria-describedby={filterPopover.id}
              icon={AdjustmentsHorizontalIcon}
            />
          </Badge>
          <Popover
            id={filterPopover.id}
            open={filterPopover.open}
            anchorEl={filterPopover.anchorEl}
            onClose={filterPopover.handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            sx={{
              mt: 1,
            }}
          >
            <div className="w-full max-w-md space-y-2 p-[10px] text-raisin-black dark:text-crystal-bell">
              {/* Favorites */}
              <div className="grid grid-cols-2 items-center justify-between">
                <span className="text-sm">Favorites</span>
                <button
                  onClick={toggleFavorites}
                  className={`p-2 rounded-full transition-colors duration-200 w-fit justify-self-end ${
                    favorites ? 'text-nue-blue' : 'text-gray-400'
                  } hover:bg-gray-100`}
                >
                  <StarIcon
                    className="w-5 h-5"
                    fill={favorites ? 'currentColor' : 'none'}
                  />
                </button>
              </div>
              <Divider flexItem />

              {/* Editor's choice */}
              <div className="grid grid-cols-2 items-center justify-between">
                <span className="flex items-center text-sm gap-1">
                  <BlurTooltip
                    title="Handpicked models recommended by Straico for their high-quality performance and outputs. Ideal for users seeking reliable, versatile options."
                    placement="top"
                  >
                    <InformationCircleIcon className="size-5" />
                  </BlurTooltip>
                  Editor's choice
                </span>
                <button
                  onClick={toggleEditorsChoice}
                  className={`px-3 py-1 rounded-full text-sm font-medium ${
                    editorsChoice
                      ? 'bg-lavender text-nue-blue'
                      : 'text-gray-600 bg-platinum'
                  } hover:opacity-80 transition-colors duration-200`}
                >
                  <div className="flex items-center justify-center gap-1">
                    <CrownIcon className="size-4" />
                    <p>EDITOR'S CHOICE</p>
                  </div>
                </button>
              </div>
              <Divider flexItem />

              {/* Features */}
              <div className="space-y-2 grid grid-cols-2">
                <span className="flex items-center text-sm gap-1">
                  <BlurTooltip
                    title="Built-in abilities that a model provides independently, without relying on external services."
                    placement="top"
                  >
                    <InformationCircleIcon className="size-5" />
                  </BlurTooltip>
                  Features
                </span>
                <div className="flex flex-wrap gap-2">
                  {FEATURES_MAP.map(({ feature, icon, label }, index) => {
                    const Icon = icon;
                    return (
                      <BadgeInformation
                        key={index + 'feature'}
                        label={label}
                        className="cursor-pointer"
                        icon={<Icon className="size-4" />}
                        variant={
                          features.includes(feature) ? 'image' : 'disabled'
                        }
                        onClick={() => toggleFilter('features', feature)}
                      />
                    );
                  })}
                </div>
              </div>
              <Divider flexItem />

              {/* Capabilities */}
              <div className="space-y-2 grid grid-cols-2">
                <span className="flex items-center text-sm gap-1">
                  <BlurTooltip
                    title="Optional enhancements you can enable for compatible models. These rely on external services, incurring additional interaction costs when activated."
                    placement="top"
                  >
                    <InformationCircleIcon className="size-5" />
                  </BlurTooltip>
                  Capabilities
                </span>
                <div className="flex flex-wrap gap-2">
                  {CAPABILITIES_MAP.map(
                    ({ capability, icon, label }, index) => {
                      const Icon = icon;
                      return (
                        <BadgeInformation
                          key={index + 'capability'}
                          label={label}
                          className="cursor-pointer"
                          icon={<Icon className="size-4" />}
                          variant={
                            capabilities.includes(capability)
                              ? 'browsing'
                              : 'disabled'
                          }
                          onClick={() =>
                            toggleFilter('capabilities', capability)
                          }
                        />
                      );
                    }
                  )}
                </div>
              </div>
              <Divider flexItem />

              {/* Applications */}
              <div className="space-y-2 grid grid-cols-2">
                <span className="flex items-center text-sm gap-1">
                  <BlurTooltip
                    title="Tags highlighting the specific types of tasks or use cases a model excels in, helping you find the right fit for your needs."
                    placement="top"
                  >
                    <InformationCircleIcon className="size-5" />
                  </BlurTooltip>
                  Applications
                </span>
                <div className="flex flex-wrap gap-2">
                  {APPLICATIONS_MAP.map(({ application: app, icon }, index) => {
                    const Icon = icon;
                    return (
                      <BadgeInformation
                        key={index + 'app'}
                        label={app}
                        className="cursor-pointer"
                        icon={<Icon className="size-4" />}
                        variant={
                          applications.includes(app) ? 'default' : 'disabled'
                        }
                        onClick={() => toggleFilter('applications', app)}
                      />
                    );
                  })}
                </div>
              </div>
              <Divider flexItem />

              {/* Other */}
              <div className="space-y-2 grid grid-cols-2">
                <span className="flex items-center text-sm gap-1">
                  <BlurTooltip
                    color="blur"
                    title="Miscellaneous attributes such as uncensored, deprecated, or unavailable, covering cases outside standard categories."
                    placement="top"
                  >
                    <InformationCircleIcon className="size-5" />
                  </BlurTooltip>
                  Other
                </span>
                <div className="flex flex-wrap gap-2">
                  {OTHERS_MAP.map(({ icon, other }, index) => {
                    const Icon = icon;
                    return (
                      <BadgeInformation
                        key={index + 'other'}
                        label={other}
                        className="cursor-pointer"
                        icon={<Icon className="size-4" />}
                        variant={
                          others?.includes(other) ? 'browsing' : 'disabled'
                        }
                        onClick={() => toggleFilter('other', other)}
                      />
                    );
                  })}
                </div>
              </div>
              <Divider flexItem />

              {/* Clear All */}
              <button
                onClick={clearAll}
                className="w-fit text-red-600 text-sm font-medium hover:text-red-700 transition-colors duration-200"
              >
                Clear All
              </button>
            </div>
          </Popover>

          {/* Sort */}
          <FilterButton
            text="Sort"
            icon={ArrowsUpDownIcon}
            aria-describedby={sortPopover.id}
            onClick={sortPopover.handleClick}
          />
          <Popover
            id={sortPopover.id}
            open={sortPopover.open}
            anchorEl={sortPopover.anchorEl}
            onClose={sortPopover.handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            sx={{
              mt: 1,
            }}
          >
            <div className="w-full max-w-md rounded-lg bg-card p-1">
              {sortItems.map((item) => (
                <button
                  key={item.id}
                  onClick={() => setSort(item.field)}
                  className="flex w-full items-center justify-between rounded-md px-2 py-1 text-sm hover:bg-nue-blue hover:bg-opacity-15 hover:text-accent-foreground"
                >
                  <span>{item.label}</span>
                  <span className="ml-2">{getSortIcon(item.field, sort)}</span>
                </button>
              ))}
            </div>
          </Popover>

          {/* Layout Toggle */}
          <ToggleButtonGroup
            exclusive
            size="small"
            color="primary"
            value={layout}
            onChange={handleChangeLayout}
          >
            <ToggleButton value="grid">
              <RectangleGroupIcon className="size-5" />
            </ToggleButton>
            <ToggleButton value="table">
              <ListBulletIcon className="size-5" />
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
      </div>
    </>
  );
};

export default FiltersContainer;
