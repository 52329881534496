import React, { useState, useEffect } from 'react';
import { Box, TextField, IconButton, Typography, Tooltip } from '@mui/material';

import {
  Bars2Icon,
  CheckCircleIcon,
  TrashIcon,
  ChevronDownIcon,
} from '@heroicons/react/24/outline';
import {
  CheckCircleIcon as CheckCircleIconSolid,
  TrashIcon as TrashIconSolid,
} from '@heroicons/react/24/solid';
import { fontWeight, styled } from '@mui/system';
import toast from 'react-hot-toast';
import ModelsModal from '../../../Features/ModelsModal';
import useChatStore from '../../Chat/store';
import { MAX_MODELS_SELECTION } from 'Constants/models';

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow placement="left" classes={{ popper: className }} />
))(({ theme, themeMode }) => ({
  [`& .MuiTooltip-tooltip`]: {
    backgroundColor: themeMode === 'dark' ? '#333333' : '#D4D5E6',
    color: themeMode === 'dark' ? '#FFFFFF' : '#5256A6',
    fontSize: '11px',
    fontWeight: 500,
    fontFamily: 'Figtree, sans-serif',
    borderRadius: '6px',
    padding: '10px 15px',
    maxWidth: 180,
    textAlign: 'center',
  },
  [`& .MuiTooltip-arrow`]: {
    color: themeMode === 'dark' ? '#333333' : '#D4D5E6',
    width: '2em',
    height: '2em',
    transform: 'rotate(0deg)',
  },
}));

const LLMComboCreate = ({ onDelete, theme, allModels }) => {
  const [value, setValue] = useState('');
  const [showModelsDialog, setShowModelsDialog] = useState(false);
  const [tempSelectedModels, setTempSelectedModels] = useState([]);
  const [isCheckHovered, setIsCheckHovered] = useState(false);
  const [isTrashHovered, setIsTrashHovered] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const { updateSelectedModels, createCombo, combos } = useChatStore(
    (state) => state
  );

  const handleCreateCombo = async () => {
    const error = validateComboName(value, false);
    if (error) {
      setErrorMessage(error);
      toast.error(error);
      return;
    }
    const isDuplicateName = combos.some(
      (combo) => combo.name.toLowerCase() === value.toLowerCase()
    );
    if (isDuplicateName) {
      setErrorMessage('A combo with this name already exists.');
      toast.error('A combo with this name already exists.');
      return;
    }

    const selectedModelIds = tempSelectedModels
      .map((model) => model._id)
      .sort();
    const isDuplicateModels = combos.some((combo) => {
      const comboModelIds = combo.llms.sort();
      return (
        selectedModelIds.length === comboModelIds.length &&
        selectedModelIds.every((id, index) => id === comboModelIds[index])
      );
    });

    if (isDuplicateModels) {
      setErrorMessage('A combo with the same models already exists.');
      toast.error('A combo with the same models already exists.');
      return;
    }

    if (tempSelectedModels.length === 0) {
      setErrorMessage('You must select at least one model.');
      toast.error('You must select at least one model.');
      return;
    }
    setErrorMessage('');

    const comboData = {
      name: value.trim(),
      llms: tempSelectedModels.map((model) => model._id),
    };

    try {
      const createdCombo = await createCombo(comboData);
      toast.success(`Combo "${createdCombo.name}" created successfully!`);
      const selectedModels = createdCombo.llms
        .map((llmId) => allModels.find((model) => model._id === llmId))
        .filter(Boolean)
        .sort((a, b) => a.abbreviation.localeCompare(b.abbreviation));
      console.log('selectedModels', selectedModels);
      updateSelectedModels(() => selectedModels);
      localStorage.setItem('currentModels', JSON.stringify(selectedModels));
      setValue('');
      setTempSelectedModels([]);
      onDelete();
    } catch {
      toast.error('Failed to create combo.');
    }
  };

  const handleSelectModel = (model) => {
    const isModelSelected = tempSelectedModels.some(
      (item) => item._id === model._id
    );

    if (isModelSelected) {
      const updatedModels = tempSelectedModels.filter(
        (item) => item._id !== model._id
      );
      setTempSelectedModels(updatedModels);
      toast.success('Model removed successfully!');
    } else {
      const updatedModels = [...tempSelectedModels, model];
      if (updatedModels.length <= MAX_MODELS_SELECTION) {
        setTempSelectedModels(updatedModels);

        toast.success('Model added successfully!');
      } else {
        toast.error(
          `You can only select up to ${MAX_MODELS_SELECTION} models.`
        );
      }
    }
  };

  const validateComboName = (name, typing) => {
    const trimmedValue = name.trim();

    if (!trimmedValue && !typing) {
      return 'The combo name cannot be empty.';
    }

    const invalidName = 'Custom Combination';
    if (trimmedValue.toLowerCase() === invalidName.toLowerCase()) {
      return `The name "${invalidName}" is not allowed.`;
    }

    const maxComboNameLength = 15;
    if (trimmedValue.length > maxComboNameLength) {
      return `The name cannot exceed ${maxComboNameLength} characters.`;
    }

    const isDuplicateName = combos.some(
      (combo) => combo.name.toLowerCase() === trimmedValue.toLowerCase()
    );
    if (isDuplicateName) {
      return 'A combo with this name already exists.';
    }

    return null;
  };

  const handleInputChange = (e) => {
    setValue(e.target.value);
  };

  const handleDelete = () => {
    setValue('');
    setTempSelectedModels([]);
    toast.success('Combo creation discarded');
    onDelete();
  };

  useEffect(() => {
    const error = validateComboName(value, true);
    setErrorMessage(error);
  }, [value, combos]);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          height: '51px',
          gap: '10px',
          backgroundColor: 'transparent',
          marginTop: '3%',
          marginBottom: '3%',
        }}
      >
        <Bars2Icon
          style={{
            width: '24px',
            height: '24px',
            color: theme === 'dark' ? '#858585' : '#9091A4',
          }}
        />

        <Box
          sx={{
            position: 'relative',
            width: '178px',
          }}
        >
          <TextField
            placeholder="Name"
            variant="outlined"
            value={value}
            onChange={handleInputChange}
            error={Boolean(errorMessage)}
            helperText={errorMessage}
            autoComplete="off"
            sx={{
              flexGrow: 1,
              width: '65%',
              height: '100%',
              '& .MuiOutlinedInput-root': {
                borderRadius: '12px',
                backgroundColor: theme === 'dark' ? '#39383A' : '#FBFBFA',
                border: Boolean(errorMessage)
                  ? '1.5px solid red'
                  : `1.5px solid ${
                      theme === 'dark' ? '#9091A461' : '#9091A461'
                    }`,
                '& fieldset': {
                  border: 'none',
                },
                '&:hover fieldset': {
                  borderColor: theme === 'dark' ? '#7F82C3' : '#5256A6',
                },
              },
              '& .MuiOutlinedInput-input': {
                textAlign: 'center',
                color: Boolean(errorMessage)
                  ? 'red'
                  : theme === 'dark'
                  ? '#FFFFFF'
                  : '#5256A6',
                fontFamily: 'Figtree, sans-serif',
                fontWeight: 500,
                fontSize: '14px',
                padding: '14px',
              },
              '& .MuiFormHelperText-root': {
                fontSize: '12px',
                color: Boolean(errorMessage)
                  ? 'red'
                  : theme === 'dark'
                  ? '#7F82C3'
                  : '#5256A6',
                fontFamily: 'Figtree, sans-serif',
                textAlign: 'center',
                marginTop: '4px',
              },
            }}
          />
        </Box>

        <Box
          sx={{
            flexGrow: 2,
            maxWidth: '100%',
            minHeight: '51px',
            backgroundColor: theme === 'dark' ? '#39383A' : '#FBFBFA',
            borderRadius: '10px',
            border: `1.5px solid ${
              theme === 'dark' ? '#9091A461' : '#9091A461'
            }`,
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            padding: '8px 12px',
            cursor: 'pointer',
            gap: '8px',
            position: 'relative',
            marginLeft: '-7%',
          }}
          onClick={() => setShowModelsDialog(true)}
        >
          {tempSelectedModels.length > 0 ? (
            tempSelectedModels.map((model, index) => (
              <Box
                key={index}
                sx={{
                  backgroundColor: theme === 'dark' ? '#787878' : '#EDEDF2',
                  borderRadius: '24px',
                  padding: '6px 12px',
                  fontSize: '12px',
                  color: theme === 'dark' ? '#EFEFEF' : '#51526C',
                  fontFamily: 'Figtree',
                  fontWeight: 500,
                  whiteSpace: 'nowrap',
                }}
              >
                {model.name}
              </Box>
            ))
          ) : (
            <Typography
              sx={{
                fontSize: '12px',
                color: theme === 'dark' ? '#EFEFEF' : '#9091A4',
              }}
            >
              Select your models
            </Typography>
          )}

          <ChevronDownIcon
            style={{
              width: '18px',
              height: '18px',
              color: theme === 'dark' ? '#FFFFFF' : '#9091A4',
              position: 'absolute',
              top: '50%',
              right: '12px',
              transform: 'translateY(-50%)',
              fontWeight: 700,
            }}
          />
        </Box>
        <CustomTooltip
          title={
            value.trim().length === 0
              ? 'You need to provide a name for the combo.'
              : 'Select at least one LLM to save the combination.'
          }
          placement="top"
          themeMode={theme}
        >
          <IconButton
            onClick={handleCreateCombo}
            onMouseEnter={() => setIsCheckHovered(true)}
            onMouseLeave={() => setIsCheckHovered(false)}
          >
            {isCheckHovered ? (
              <CheckCircleIconSolid
                style={{ width: '24px', height: '24px', color: '#5256A6' }}
              />
            ) : (
              <CheckCircleIcon
                style={{ width: '24px', height: '24px', color: '#9091A4' }}
              />
            )}
          </IconButton>
        </CustomTooltip>

        <IconButton
          onClick={handleDelete}
          onMouseEnter={() => setIsTrashHovered(true)}
          onMouseLeave={() => setIsTrashHovered(false)}
        >
          {isTrashHovered ? (
            <TrashIconSolid
              style={{
                width: '24px',
                height: '24px',
                color: theme === 'dark' ? '#FFFFFF' : '#5256A6',
              }}
            />
          ) : (
            <TrashIcon
              style={{
                width: '24px',
                height: '24px',
                color: theme === 'dark' ? '#FFFFFF' : '#9091A4',
              }}
            />
          )}
        </IconButton>
      </Box>
      <ModelsModal
        open={showModelsDialog}
        selectedModels={tempSelectedModels}
        onClose={() => setShowModelsDialog(false)}
        handleCombos={handleSelectModel}
        nocapabilities={true}
        themeValue={theme}
      />
    </>
  );
};

export default LLMComboCreate;
