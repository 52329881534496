const dev = {
  baseURL: 'http://localhost:3050/api/',
  socketURL: 'https://sio.straico.com',
  landingPageUrl: 'http://localhost:3050',
  stripe: {
    free: 'free_trial',
    entry: 'price_1Mzst5ImOS91TsUeaRCOjzb9',
    pro: 'price_1JLQhlHIZYvvnKlakrqF8khB',
  },
  baseFrontUrl: 'http://localhost:3000',
  communityUrl: 'http://localhost:4000/community',
  enviroment: 'development',
};

const prod = {
  baseURL: '/api/',
  socketURL: 'https://sio.straico.com',
  landingPageUrl: 'https://app.openaitemplate.com',
  stripe: {
    free: 'free_trial',
    entry: 'price_1JLpWpHIZYvvnKlaHxiCr7Js',
    pro: 'price_1JLpWxHIZYvvnKlamATZ7Awm',
  },
  baseFrontUrl: 'https://platform.straico.com',
  communityUrl: 'https://straico.com/community',
  enviroment: 'production',
};

const config = process.env.NODE_ENV === 'development' ? dev : prod;

export default config;
