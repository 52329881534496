import { SparklesIcon } from '@heroicons/react/24/outline';
import { Tab, Tabs } from '@mui/material';
import useModelsModalStore from 'Features/ModelsModal/store';
import { a11yProps } from 'utils/styles';
import CapabilitiesIcon from '../../../Theme/icons/capabilitiesIcon';

const TabsContainer = ({ nocapabilities }) => {
  const { tab, setTab } = useModelsModalStore((state) => ({
    tab: state.tab,
    setTab: state.setTab,
  }));

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };
  return (
    !nocapabilities && (
      <div className="flex border-b border-gray-200">
        <Tabs
          value={tab}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab
            label="LLM Selection"
            {...a11yProps(0)}
            iconPosition="start"
            icon={<SparklesIcon className="size-4" />}
            sx={{
              minHeight: 'auto',
              textTransform: 'initial',
            }}
          />
          <Tab
            label="Capabilities"
            {...a11yProps(1)}
            iconPosition="start"
            icon={
              <CapabilitiesIcon
                className={`w-4 h-4 ${
                  tab === 1 ? 'stroke-violet-blue' : 'stroke-cool-gray'
                }`}
              />
            }
            sx={{
              minHeight: 'auto',
              textTransform: 'initial',
            }}
          />
        </Tabs>
      </div>
    )
  );
};

export default TabsContainer;
